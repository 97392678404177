import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LanguageContainer } from '@/components/Context/LanguageContext';

import { App } from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

const app = document.getElementById('app');

if (!app) throw new Error('No root element found');

const root = createRoot(app);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <LanguageContainer.Provider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </LanguageContainer.Provider>
);
