import classnames from 'classnames';
import { range } from 'lodash';
import React, { useMemo, FC } from 'react';
import { Icon } from '../Icon';

export interface Props {
  firstPage?: number;
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
  boundryCount?: number;
}

interface PaginationButtonProps {
  selected?: boolean;
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  children: any;
}

const PaginationButton: FC<PaginationButtonProps> = ({ selected = false, className, children, ...rest }) => {
  return (
    <button
      className={classnames(
        'rounded p-1 hover:bg-internationalBlue-5 h-9 w-9 text-base',
        selected && 'bg-highlightingBlue text-white hover:text-darkBlue-70',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export const TablePagination: FC<Props> = ({
  firstPage = 1,
  currentPage,
  onPageChange,
  siblingCount = 1,
  boundryCount = 0,
  pageCount
}) => {
  const meta = useMemo(() => {
    const lastPage = firstPage + pageCount - 1;
    const siblings = range(currentPage - siblingCount, currentPage + siblingCount + 1).filter(
      (pageNo) => pageNo >= firstPage && pageNo <= lastPage
    );
    const boundry = {
      left: range(firstPage, firstPage + boundryCount + 1).filter(
        (page) => !siblings.includes(page) && page >= firstPage && page < currentPage - siblingCount
      ),
      right: range(lastPage - boundryCount, lastPage + 1).filter(
        (page) => !siblings.includes(page) && page >= currentPage + siblingCount && page <= lastPage
      )
    };
    const leftBoundryAndSiblingsAreConsecutive = siblings[0] - firstPage === boundry.left.length;
    const rightBoundryAndSiblingsAreConsecutive = lastPage - siblings[siblings.length - 1] === boundry.right.length;

    return {
      hasHiddenLeftSiblings: !leftBoundryAndSiblingsAreConsecutive,
      hasHiddenRightSiblings: !rightBoundryAndSiblingsAreConsecutive,
      siblings,
      boundry,
      nextDisabled: lastPage === currentPage,
      previousDisabled: firstPage === currentPage
    };
  }, [pageCount, firstPage, currentPage, siblingCount]);

  return (
    <div className="flex items-center justify-center gap-4 text-darkBlue-70">
      <PaginationButton onClick={() => onPageChange(currentPage - 1)} disabled={meta.previousDisabled}>
        <Icon type="chevronLeft" sizeClassName="text-sm" />
      </PaginationButton>
      {meta.boundry.left.map((pageNo) => (
        <PaginationButton key={pageNo} onClick={() => onPageChange(pageNo)}>
          {pageNo}
        </PaginationButton>
      ))}
      {meta.hasHiddenLeftSiblings && <div>{'...'}</div>}
      {meta.siblings.map((pageNo) => (
        <PaginationButton key={pageNo} selected={pageNo === currentPage} onClick={() => onPageChange(pageNo)}>
          {pageNo}
        </PaginationButton>
      ))}
      {meta.hasHiddenRightSiblings && <div>{'...'}</div>}
      {meta.boundry.right.map((pageNo) => (
        <PaginationButton key={pageNo} onClick={() => onPageChange(pageNo)}>
          {pageNo}
        </PaginationButton>
      ))}
      <PaginationButton onClick={() => onPageChange(currentPage + 1)} disabled={meta.nextDisabled}>
        <Icon type="chevronRight" sizeClassName="text-sm" />
      </PaginationButton>
    </div>
  );
};
