import React, { FC } from 'react';

import { BulkActionButton, RowActionButton } from '../types';
import { TableEmpty } from './TableEmpty';
import { LoadingSize, Spinner, SpinnerColor } from '../../Loading';
import { TableRows } from './TableRows';
import { ColumnDef, flexRender, Table } from '@tanstack/react-table';

interface Props {
  table: Table<Record<string, unknown>>;
  bulkActionButtons?: BulkActionButton[];
  rowActionButtons?: RowActionButton[];
  trClassName?: string;
  flexRender: typeof flexRender;
  columns: ColumnDef<Record<string, unknown>, any>[];
  noResultsTitle: string;
  noResultsMessage: string;
  loading?: boolean;
  onRowClick: (row: Record<string, unknown>) => void;
}

export const TableBody: FC<Props> = ({
  rowActionButtons,
  bulkActionButtons,
  table,
  flexRender,
  noResultsTitle,
  noResultsMessage,
  columns,
  trClassName,
  loading = false,
  onRowClick
}) => {
  return (
    <tbody>
      {!loading &&
        (table.getRowModel()?.rows?.length > 0 ? (
          <TableRows
            table={table}
            flexRender={flexRender}
            bulkActionButtons={bulkActionButtons}
            rowActionButtons={rowActionButtons}
            trClassName={trClassName}
            onRowClick={onRowClick}
          />
        ) : (
          <tr>
            <td colSpan={rowActionButtons?.length ? columns?.length + 1 : columns?.length}>
              <TableEmpty title={noResultsTitle} message={noResultsMessage} />
            </td>
          </tr>
        ))}
      {loading && (
        <tr>
          <td colSpan={rowActionButtons?.length ? columns?.length + 1 : columns?.length}>
            <div className="flex justify-center items-center py-12">
              <Spinner size={LoadingSize.Large} fillColor={SpinnerColor.Primary} isIndeterminate />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};
