import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

export const PrivacyNotice: React.FC = () => {
  return (
    <div className="pt-6 pl-6 mt-4 -mx-6 text-xs border-t text-darkBlue-50">
      <FormattedMessage
        id={translations.pages.signIn.privacy}
        values={{
          span: ((text: string) => (
            // TODO add proper link
            <Link to="/temp" className="underline">
              {text}
            </Link>
          )) as unknown as React.ReactNode
        }}
      />
    </div>
  );
};
