import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast/headless';
import { DevTool } from '@hookform/devtools';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import React, { FC, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { urls } from '@/constants';
import { translations } from '@/locales';
import {
  getClientList,
  getPrinterProfileGroups,
  getPrinterList,
  createPrinterProfile,
  updatePrinterProfile
} from '@/services/api';

import { Button } from '@nshift/common/components/Button';
import { FormCombobox } from '@nshift/common/components/Select';
import { FormToggle } from '@nshift/common/components/FormToggle';
import { FormNumberField, FormTextField } from '@nshift/common/components/Input';

import { mockPrinterOptions } from './constants';
import { Printer, PrinterProfileFormData, PrinterProfileGroupsResponse, printFormat } from './types';
import { PrinterProfileGroupsSelector } from './PrinterProfileGroupsSection/PrinterProfileGroupsSelector';

interface PrinterProfileFormProps {
  profileId?: string;
  defaultData?: any;
  key: any;
  editMode?: boolean;
}

const schema = yup.object().shape({});

export const PrinterProfileForm: FC<PrinterProfileFormProps> = ({ profileId, defaultData, key, editMode }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  console.log('defaultData', { defaultData });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    // defaultValues: mock,
    defaultValues: defaultData,
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const [profileName, client, printerId] = useWatch({
    control,
    name: ['profileName', 'clientId', 'printerId', 'groups']
  });
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data: { clients = [] } = {}, isLoading: clientsLoading } = useQuery<{
    clients: Array<{ clientId: number; name: string }>;
  }>(['get-clients'], getClientList(0, 100));

  const { data: { printers = [] } = {}, isLoading: printersLoading } = useQuery<{
    printers: Array<Printer>;
  }>(['get-printers', client], getPrinterList(0, 1000, client?.length > 0 ? [Number(client)] : []));

  const handleError = (error: any) => {
    setErrorMessage(error.response?.data?.message);
  };

  const handleProfileCreate = (data: PrinterProfileFormData) => {
    createPrinterProfile(data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.createSuccess }));
      })
      .catch(handleError);
  };
  const handleProfileUpdate = (data: PrinterProfileFormData) => {
    updatePrinterProfile(Number(profileId), data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.updateSuccess }));
      })
      .catch(handleError);
  };

  const onSubmit = handleSubmit((data) => {
    setErrorMessage(undefined);
    if (editMode) {
      handleProfileUpdate(data);
    } else {
      handleProfileCreate(data);
    }
  });

  const medias = useMemo(() => {
    return (
      printers
        ?.find((printer) => printerId?.includes(printer.printerId.toString()))
        ?.medias?.map((media) => ({ id: media, name: media })) ?? []
    );
  }, [printerId]);

  const printerOptions = useMemo(() => {
    const options =
      printers.find((printer) => printerId?.includes(printer.printerId.toString()))?.options ?? mockPrinterOptions;

    return options;
  }, [printers, printerId]);

  const { data: { groups: printerProfileGroups = [] } = {} } = useQuery<PrinterProfileGroupsResponse>(
    ['get-printer-profile-groups'],
    getPrinterProfileGroups,
    { refetchOnWindowFocus: false }
  );

  return (
    <div>
      <DevTool control={control} />

      <form
        className="flex flex-col gap-6"
        onSubmit={onSubmit}
        key={key}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      >
        <div className="px-6 py-8 space-y-6 bg-white rounded-lg shadow">
          <div className="text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.general} />
          </div>

          <div className="grid w-2/3 grid-cols-2 gap-4">
            <FormTextField
              errors={errors}
              id="profileName"
              name="profileName"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.profileName })}
              inputClassName="w-full"
              className="w-full"
              required
            />

            <FormToggle
              name="active"
              register={register}
              label={intl.formatMessage({ id: translations.domains.printerProfilesStatusFilter.ACTIVE })}
            />
          </div>

          <div className="grid grid-cols-2 gap-y-6 gap-x-4">
            {!clientsLoading && (
              <FormCombobox
                items={clients?.map(({ clientId, name }) => ({ id: clientId.toString(), name }))}
                label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.client })}
                control={control}
                name="clientId"
                required
              />
            )}

            {!printersLoading && (
              <FormCombobox
                items={printers?.map(({ printerId, name }) => ({ id: printerId.toString(), name }))}
                label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer })}
                control={control}
                name="printerId"
                required
              />
            )}

            <FormCombobox
              items={printFormat}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format })}
              control={control}
              name="format"
              required
            />

            {medias?.length > 0 && (
              <FormCombobox
                items={medias}
                label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media })}
                control={control}
                name="media"
                required
              />
            )}
          </div>

          <div className="grid grid-cols-3 gap-x-4">
            <FormNumberField
              id="offsetX"
              name="offsetX"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetX })}
              inputClassName="w-full"
              className="w-full"
              maxValue={100}
              minValue={-100}
            />

            <FormNumberField
              id="offsetY"
              name="offsetY"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetY })}
              inputClassName="w-full"
              className="w-full"
              maxValue={100}
              minValue={-100}
            />

            <FormTextField
              errors={errors}
              id="layouts"
              name="layouts"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.layouts })}
              inputClassName="w-full"
              className="w-full"
            />
          </div>
        </div>

        <div className="px-6 py-8 bg-white rounded-lg shadow">
          <div className="mb-6 text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.profileGroups} />
          </div>
          {printerProfileGroups.length > 0 && (
            <PrinterProfileGroupsSelector
              name="groups"
              initialGroups={printerProfileGroups.map(({ groupName, ...rest }) => ({ name: groupName, ...rest }))}
              profileName={profileName}
              control={control}
            />
          )}
        </div>

        <div className="px-6 py-8 bg-white rounded-lg shadow">
          <div className="mb-6 text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.printerOptions} />
          </div>

          <div className="grid grid-cols-3 gap-4">
            {printerOptions?.map(({ key, values }) => (
              <FormCombobox
                key={key}
                items={values.map((value) => ({ id: value.toString(), name: value.toString() }))}
                control={control}
                label={key}
                name={`printerOptions.${key}`}
                className="w-full"
              />
            ))}
          </div>
        </div>

        <div className="text-warningRed">{errorMessage}</div>

        <div className="flex justify-end col-span-2">
          <Button linkTo={urls.printers.profiles.base} className="w-32 ml-4" appearance="tertiary">
            <FormattedMessage id={translations.application.buttons.cancel} />
          </Button>

          <Button className="ml-4 w-52" appearance="primary" type="submit" isLoading={isSubmitting}>
            <FormattedMessage id={translations.application.buttons.save} />
          </Button>
        </div>
      </form>
    </div>
  );
};

// Controller for SimpleCombobox alternative method

// <Controller
//   name={'single'}
//   control={control}
//   defaultValue={[mock[4].id]}
//   render={({ field }) => <SimpleCombobox selectionMode="single" {...field} items={mock} label="Single" />}
// />

// <Controller
//   name={'multiple'}
//   control={control}
//   defaultValue={[mock[3].id, mock[5].id, mock[7].id]}
//   render={({ field }) => <SimpleCombobox selectionMode="multiple" {...field} items={mock} label="Multiple" />}
// />

// Mock data for SimpleCombobox
/* const mock = faker.helpers.multiple(
  () => ({
    id: faker.string.uuid(),
    name: faker.location.city()
  }),
  { count: 10 }
);
*/
