import classnames from 'classnames';

export const getSelectButtonText = (defaultId, defaultText, selected, items) => (): string => {
  return selected ? findSelectedName(selected, items) : findSelectedName(defaultId, items) ?? defaultText;
};

export const findSelectedName = (selected, items) => {
  const selectedItem = (items as any[]).find((item) => selected === item.id);
  return selectedItem?.name;
};

export const labelHalfFillBackground = (disabled: boolean) => {
  return classnames(
    disabled ? 'before:bg-internationalBlue-3' : 'before:bg-white group-hover:before:bg-internationalBlue-5',
    'before:absolute before:h-1/2 before:-z-10 before:left-0 before:bottom-0',
    'before:overflow-hidden before:w-full'
  );
};
