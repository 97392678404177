import React from 'react';
import Helmet from 'react-helmet';

import './styles/base.scss';
import 'tailwindcss/tailwind.css';

import { AppContent } from './components/App/AppContent';
import { Session } from './containers/SessionContainer';
import { I18nProvider } from 'react-aria';
import { Toaster } from '@nshift/common/components/Toast';

export const App: React.FC = () => {
  return (
    <I18nProvider locale="en-GB">
      <Session.Provider>
        <Helmet defaultTitle="Print by nShift" titleTemplate="%s - Print by nShift">
          {/* Favicon */}
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <Toaster />
        <AppContent />
      </Session.Provider>
    </I18nProvider>
  );
};
