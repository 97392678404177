import React from 'react';
import classnames from 'classnames';

import icon from './style.module.css';

export type Type = keyof typeof icon;

export type IconAppearance = 'none' | 'blue' | 'highlightingBlue' | 'green' | 'yellow' | 'red' | 'pink' | 'white';

export type Size = 'small' | 'medium' | 'large';

const textSize: Record<Size, string> = {
  small: 'text-[12px] leading-[12px] md:text-[16px] md:leading-[16px]',
  medium: 'text-[16px] leading-[16px] md:text-[24px] md:leading-[24px]',
  large: 'text-[24px] leading-[24px] md:text-[32px] md:leading-[32px]'
};

const appearanceToClassNameMap: Record<IconAppearance, { light: string; regular: string }> = {
  none: {
    light: 'text-darkBlue-30',
    regular: 'text-darkBlue-80'
  },
  blue: {
    light: 'text-internationalBlue-30',
    regular: 'text-internationalBlue'
  },
  highlightingBlue: {
    light: 'text-highlightingBlue-30',
    regular: 'text-highlightingBlue'
  },
  green: {
    light: 'text-confirmingGreen-30',
    regular: 'text-confirmingGreen'
  },
  yellow: {
    light: 'text-alertingYellow-30',
    regular: 'text-alertingYellow'
  },
  red: {
    light: 'text-warningRed-30',
    regular: 'text-warningRed'
  },
  pink: {
    light: 'text-pink-30',
    regular: 'text-pink'
  },
  white: {
    light: 'text-white',
    regular: 'text-white'
  }
};

export interface Props {
  type: Type;
  appearance?: IconAppearance;
  light?: boolean;
  className?: string;
  size?: Size;
  sizeClassName?: string;
  iconColor?: string;
}

export const Icon: React.FC<Props> = ({
  appearance = 'none',
  className,
  size = textSize.medium,
  sizeClassName,
  light = false,
  iconColor = '',
  type,
  ...rest
}) => {
  const color = iconColor
    ? iconColor
    : light
      ? appearanceToClassNameMap[appearance].light
      : appearanceToClassNameMap[appearance].regular;

  return (
    <i
      className={classnames(
        'align-middle inline-flex text-center',
        `${type}`,
        icon[type],
        color,
        className,
        sizeClassName || textSize[size] || textSize.medium
      )}
      {...rest}
    />
  );
};
