import * as React from 'react';
import classnames from 'classnames';

import { Button } from '../Button';
import { Icon } from '../Icon';

export const TableRowDetailsDrawer = ({ row, actionButtons, className = '', onClose, children }) => {
  return (
    <div
      className={classnames(
        'z-20 fixed bottom-0 right-0 w-96 bg-highlightingBlue-3 top-[104px] overflow-y-scroll shadow',
        className
      )}
    >
      <div className="relative flex h-full">
        <Button className="w-6 px-[0px] h-full rounded-none" onClick={onClose}>
          <Icon type="chevronRight" appearance="blue" sizeClassName="text-sm" aria-hidden="true" />
        </Button>

        <div className="flex flex-col justify-between w-full h-full">
          <div className="mt-8">{children}</div>

          <div className="flex flex-col w-full gap-4 py-4">
            {actionButtons?.map(({ id, label, handlerFn }) => (
              <Button key={id} onClick={() => handlerFn(row)} appearance="tertiary">
                {label}
              </Button>
            ))}
          </div>
        </div>

        <div className="w-6" />
      </div>
    </div>
  );
};
