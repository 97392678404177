import React, { FocusEventHandler, ForwardedRef, forwardRef, MouseEventHandler } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { get } from 'lodash';

import { ErrorMessage } from '@hookform/error-message';

import style from './Input.module.scss';
import { RequiredTag } from '../RequiredTag';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label?: string;
  inputClassName?: string;
  required?: boolean;
  errors?: any;
  value?: any;
  onChange: any;
  fullWidth?: boolean;
  ref?: any;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: any;
  onClick?: MouseEventHandler<HTMLInputElement>;
  noBorder?: boolean;
}

// This is a different approach, I feel like the component itself shouldn't be too tightly integrated with RHF
// Let me know if you come up with something better, currently only the errors are part of RHF for convenience
export const NewTextInput: React.FC<React.PropsWithChildren<Props>> = forwardRef(
  (
    {
      id,
      label,
      className,
      inputClassName,
      required = false,
      name,
      disabled,
      errors,
      placeholder = ' ',
      fullWidth = false,
      onFocus,
      onBlur,
      onClick,
      noBorder = false,
      ...rest
    },
    ref
  ) => {
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);

    return (
      <div className="w-full">
        <div
          className={classnames(
            fullWidth ? 'w-full' : 'w-[fit-content]',
            'relative group text-darkBlue-60 disabled:text-darkBlue-40',
            disabled && 'pointer-events-none',
            className
          )}
        >
          <input
            ref={ref as ForwardedRef<HTMLInputElement>}
            className={classnames(
              style.root,
              fullWidth && 'w-full',
              'h-10 outline-none px-3 py-2 !text-sm peer',
              !noBorder && 'rounded-lg',
              'disabled:bg-darkBlue-3 disabled:text-darkBlue-40 disabled:pointer-events-none',
              hasError ? 'focus:bg-white' : 'hover:bg-highlightingBlue-10',
              inputClassName
            )}
            autoComplete="off"
            onFocus={onFocus ? onFocus : undefined}
            onBlur={onBlur ? onBlur : undefined}
            onClick={onClick ? onClick : undefined}
            {...{ id, disabled, placeholder, ...rest }}
          />

          {label && (
            <label
              className={classnames(
                'absolute left-[9px] top-px text-xs text-darkBlue-70 transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none',
                'peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm',
                'group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-darkBlue-60',
                disabled && 'peer-placeholder-shown:text-darkBlue-40 !text-darkBlue-40'
              )}
              htmlFor={id}
            >
              {label} {required && <RequiredTag />}
            </label>
          )}

          {/* This fieldset+legend is used for the border and notch transition*/}
          <fieldset
            className={classnames(
              'inset-0 absolute  disabled:border-darkBlue-20 pointer-events-none mt-[-9px] invisible',
              !noBorder && 'border rounded-lg',
              hasError ? 'border-warningRed' : 'border-darkBlue-30',
              'peer-placeholder-shown:visible group-focus-within:border-1',
              disabled
                ? 'peer-placeholder-shown:!text-darkBlue-40'
                : hasError
                  ? 'group-focus-within:!border-warningRed'
                  : 'group-focus-within:!border-highlightingBlue group-hover:border-highlightingBlue'
            )}
          >
            <legend
              className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px]
          group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap"
            >
              {label} {required && <RequiredTag />}
            </legend>
          </fieldset>

          {/* This fieldset+legend always has a notch and is shown when the input is filled, instead of the other,
              so the notch doesn't vanish when you unfocus the field */}
          <fieldset
            className={classnames(
              'inset-0 absolute pointer-events-none mt-[-9px] visible',
              !noBorder && 'border rounded-lg',
              'peer-placeholder-shown:invisible group-focus-within:border-1',
              hasError
                ? 'group-focus-within:!border-warningRed'
                : 'group-focus-within:!border-highlightingBlue group-hover:border-highlightingBlue border-darkBlue-30',
              disabled && '!text-darkBlue-20'
            )}
          >
            <legend className="invisible max-w-full px-1 ml-2 text-xs whitespace-nowrap">{label} {required && <RequiredTag />}</legend>
          </fieldset>
        </div>

        {errors && (
          <ErrorMessage
            {...{ errors, name }}
            render={({ message }) => (
              <div className="text-sm text-warningRed">
                <FormattedMessage id={message} values={{ path: label?.toLowerCase() }} />
              </div>
            )}
          />
        )}
      </div>
    );
  }
);
