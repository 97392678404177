import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { translations } from '@/locales';
import { getPrinterProfileDetail } from '@/services/api';
import { printerProfileToFormData } from '@/util/adapters';

import { LoadingSize, Spinner, SpinnerColor } from '@nshift/common/components/Loading';

import { PrinterProfileForm } from './PrinterProfileForm';
import { InitialPrinterProfileFormData } from './types';

export const PrinterProfileDetailPage: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery<InitialPrinterProfileFormData>(
    'get-printer-profile',
    () => getPrinterProfileDetail(Number(id)).then(printerProfileToFormData),
    {
      enabled: isEdit,
      cacheTime: 0
    }
  );

  return (
    <div className="w-full max-w-4xl py-20 mx-auto">
      <div className="mb-8 text-3xl font-semibold font-title">
        <FormattedMessage
          id={isEdit ? translations.pages.printerProfiles.edit.title : translations.pages.printerProfiles.create.title}
        />
      </div>

      <div className="flex flex-col">
        {isLoading ? (
          <div className="flex items-center justify-center p-6 mx-6">
            <Spinner isIndeterminate size={LoadingSize.Medium} fillColor={SpinnerColor.Primary} />
          </div>
        ) : (
          <PrinterProfileForm defaultData={isEdit ? data : {}} key={id} editMode={isEdit} profileId={id ?? '0'} />
        )}
      </div>
    </div>
  );
};
