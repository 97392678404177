import React from 'react';

import { BaseDraggableList } from './BaseDraggableList';

export interface DraggableListProps {
  items: any[] | any;
  disabledKeys?: string[] | number[];
  showDeleteButtons?: boolean;
  onReorder?: (e, updatedList) => void;
}

export const DraggableList = (props: DraggableListProps) => {
  const moveBefore = (arr, index) => {
    // Check if the index is valid and not at the start of the array
    if (index > 0 && index < arr.length) {
      // Swap the element at index with the one before it
      [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];
    }
    return arr;
  };

  const moveDown = (arr, index) => {
    // Check if the index is valid and not at the end of the array
    if (index >= 0 && index < arr.length - 1) {
      // Swap the element at index with the one after it
      [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
    }
    return arr;
  };

  const onReorder = (e) => {
    const dropEvent = e;
    // setLatestDropEvent(dropEvent);
    const updatedList = [...props.items];
    const index = updatedList.findIndex((item) => item.key === dropEvent.target.key);
    switch (dropEvent.target.dropPosition) {
      case 'before':
        moveBefore(updatedList, index);
        break;
      case 'after':
        moveDown(updatedList, index);
        break;
      case 'on':
        // Just here for illustration purposes. Object was not moved.
        break;
      default:
        break;
    }
    props.onReorder(dropEvent, updatedList);
  };

  return <BaseDraggableList {...props} items={props.items} onReorder={onReorder} />;
};
