import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { getClientList } from '@/services/api/clients';
import { useTableManager } from '@/hooks/useTableManager';

import { Table } from '@nshift/common/components/Table';

import { ClientFilterSection } from './ClientFilterSection';
import { useClientTableConfig } from './ClientTableConfig';
import { ClientFilters, ClientsResponseData } from './types';

const clientSetupTranslations = translations.pages.clientSetup;

const initialFilterState: ClientFilters = {
  apiKeys: [],
  name: '',
  id: '',
  ip: '',
  statuses: [],
  blocked: false
};

export const ClientSetupPage: React.FC = () => {
  const intl = useIntl();

  const { pageSize, page, filters, setPageSize, setPage, setFilters } =
    useTableManager<ClientFilters>(initialFilterState);

  const {
    data: { clients = [], totalPages, currentPage = 0 } = {},
    isLoading,
    refetch
  } = useQuery<ClientsResponseData>(['clients', page, pageSize, filters], getClientList(page, pageSize, filters), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // Add id to each client as the backend calls it clientId
      data?.clients.map((client: any) => ({
        ...client,
        id: client.clientId
      }));
    }
  });

  const { bulkActionButtons, rowActionButtons, colsConfig } = useClientTableConfig({
    refetch
  });

  return (
    <div className="w-full py-20">
      <div className="flex items-center justify-between px-6 mb-6">
        <h1 className="text-3xl font-semibold">
          <FormattedMessage id={clientSetupTranslations.setup} />
        </h1>
      </div>

      <div className="flex flex-col py-6 mx-6 bg-white rounded-lg shadow">
        <div className="px-6">
          <ClientFilterSection
            initialFilterState={initialFilterState}
            defaultFilterState={filters}
            onApplyFilters={setFilters}
          />
        </div>

        <Table
          id="ClientsTable"
          data={clients}
          columns={colsConfig}
          rowIdProperty="clientId"
          selectedPage={currentPage + 1}
          onPageChange={(page) => setPage(page - 1)}
          defaultPageSize={pageSize}
          onPageSizeChange={setPageSize}
          rowActionButtons={rowActionButtons}
          bulkActionButtons={bulkActionButtons}
          rowCount={(totalPages ?? 0) * pageSize} // to be changed when the backend is giving the rowcount
          noResultsTitle={intl.formatMessage(
            { id: translations.miscellaneous.table.empty.title },
            { items: intl.formatMessage({ id: translations.pages.clientSetup.items }) }
          )}
          noResultsMessage={intl.formatMessage({ id: translations.miscellaneous.table.empty.message })}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
