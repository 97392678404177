import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translations } from '@/locales';

import { ComboBox } from '@nshift/common/components/Select';
import { PrinterProfileGroupPriorityList } from './PrinterProfileGroupPriorityList';

import type { PrinterGroup, SelectorPrinterGroup } from '../priorities/types';
import { tempProfileName } from '../priorities/utils';
import { PrinterProfileGroupsTray } from './PrinterProfileGroupsTray';

interface Props {
  profileName?: string;
  initialGroups?: Array<PrinterGroup>;
  name?: string;
  onChange?: (updatedPrinterGroups: SelectorPrinterGroup[]) => void;
  savedPrinterGroups: SelectorPrinterGroup[];
}

export const PrinterProfileGroups: FC<Props> = ({
  initialGroups = [],
  profileName,
  onChange,
  name,
  savedPrinterGroups = []
}) => {
  const intl = useIntl();

  const [groups, setGroups] = useState<PrinterGroup[]>(initialGroups);
  const [activeGroupId, setActiveGroupId] = useState<string | null>(savedPrinterGroups[0]?.id || null);

  const nextId = useMemo(() => {
    return groups?.length > 0
      ? groups
          .map(({ id }) => id)
          .sort()
          .reverse()[0] + 1
      : 0;
  }, [groups]);

  const selectedGroupIds = useMemo(() => {
    return new Set(savedPrinterGroups.map(({ id }) => String(id)));
  }, [savedPrinterGroups]);

  const selectedGroups = useMemo<PrinterGroup[]>(() => {
    return savedPrinterGroups.map(({ id, groupName, priority: updatedPriority }) => {
      const currentProfileName = profileName ?? tempProfileName(nextId);
      const otherProfilesInGroups =
        groups
          .find(({ id: groupId }) => String(groupId) === id)
          ?.printerProfileAndPriorities?.filter(({ name }) => name !== currentProfileName)
          .map(({ name, priority }) => ({
            name,
            priority
          })) || [];
      const updatedProfilesInGroups = [
        ...otherProfilesInGroups,
        { name: currentProfileName, priority: updatedPriority }
      ];

      return {
        id,
        name: groupName,
        printerProfileAndPriorities: updatedProfilesInGroups
      };
    });
  }, [groups, savedPrinterGroups, activeGroupId, profileName]);

  const addNonExistingGroup = (inputValue: string) => {
    // Both add new value to selectedGroupIds and also to the selectable choices in the combobox
    // The new group does not currently have an id at this stage
    setGroups((prev) => [
      ...prev,
      {
        id: nextId,
        name: inputValue,
        printerProfileAndPriorities: []
      }
    ]);
    setActiveGroupId(String(nextId));
    onChange?.([
      ...savedPrinterGroups,
      {
        id: String(nextId),
        groupName: inputValue,
        priority: 0
      }
    ]);
  };

  const handleGroupSelectionChange = (newSelectedGroupIds: Set<string>) => {
    const newSelectedGroupIdsArray = Array.from(newSelectedGroupIds);
    if (newSelectedGroupIds.size > 0) {
      const lastSelectedGroupId = Array.from(newSelectedGroupIds).pop();
      setActiveGroupId(lastSelectedGroupId);
    }

    const updatedSelectedGroups = newSelectedGroupIdsArray.map((id) => {
      const { printerProfileAndPriorities } = groups.find((group) => String(group.id) === id) ?? {};
      const prioritiesInGroup = printerProfileAndPriorities?.map(({ priority }) => priority) || [];
      const lowestPriority = prioritiesInGroup.length > 0 ? Math.max(...prioritiesInGroup) + 1 : 0;

      const profilePriority = selectedGroupIds.has(id)
        ? (savedPrinterGroups.find((group) => group.id === id)?.priority ?? // Get the profile priority from the savedPrinterGroups
          (printerProfileAndPriorities?.find((profile) => profile.name === profileName)?.priority || // Get the profile priority from the groups if it is a newly added group
            lowestPriority))
        : lowestPriority;
      return {
        id,
        groupName: groups.find(({ id: groupId }) => groupId === Number(id))?.name || '',
        priority: profilePriority
      };
    });

    onChange?.(updatedSelectedGroups);
  };

  const removeGroupFromProfile = (groupId: string) => {
    // Also remove group from updatedPrinterGroups
    const newPrinterGroups = savedPrinterGroups.filter((group) => {
      return group.id !== groupId;
    });
    onChange?.(newPrinterGroups);
    if (activeGroupId === groupId) {
      setActiveGroupId(newPrinterGroups?.length > 0 ? (newPrinterGroups?.[0].id ?? null) : null);
    }
  };

  const onPriorityReorder = (newPriority: any) => {
    const updatedPrinterGroups = savedPrinterGroups.map((group) =>
      group.id === activeGroupId
        ? {
            ...group,
            priority: newPriority
          }
        : group
    );
    onChange?.(updatedPrinterGroups);
  };

  return (
    <div>
      <ComboBox
        label={intl.formatMessage({ id: translations.pages.printerProfiles.group.title })}
        items={groups}
        selectedKeys={[...selectedGroupIds]}
        allowCustomValues
        selectionMode="multiple"
        onSelectionChange={(ids) => handleGroupSelectionChange(ids)}
        onAddClick={addNonExistingGroup}
        name={name}
      />

      <div className="mt-1 text-sm font-normal text-darkBlue-60">
        <FormattedMessage id={translations.pages.printerProfiles.group.description} />
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <PrinterProfileGroupsTray
          activeGroupId={activeGroupId}
          selectedGroups={selectedGroups}
          onGroupSelect={setActiveGroupId}
          onGroupRemove={removeGroupFromProfile}
        />

        <PrinterProfileGroupPriorityList
          profileName={profileName}
          groups={selectedGroups}
          activeGroupId={activeGroupId}
          onReorder={onPriorityReorder}
        />
      </div>
    </div>
  );
};
