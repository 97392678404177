import moment from 'moment';
import { isNil } from 'lodash';

export const dateTimeServerFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeDisplayFormat = 'YYYY-MM-DD HH:mm:ss';

export const dateFormatter = (
  date: string,
  inputFormat: string = dateTimeServerFormat,
  outputFormat: string = dateTimeDisplayFormat
): string => {
  if (isNil(date)) {
    return '';
  }
  const momentDate = moment(date, inputFormat);
  if (momentDate.isValid()) {
    return momentDate.format(outputFormat);
  }
  return '';
};
