import React, { FC, useEffect, useRef } from 'react';
import { Item, useOverlayTriggerState, useSelectState } from 'react-stately';
import { AriaSelectProps, useOverlayTrigger, useSelect } from 'react-aria';
import { Popover } from '../Modal';
import { GridList } from './GridList';
import { SelectButton } from './SelectButton';
import { getSelectButtonText } from './constants';

// Reuse the ListBox, Popover, and Button from your component library. See below for details.
// import { Button, ListBox, Popover } from 'your-component-library';
export interface SelectItem {
  id: string;
  name: string;
  disabled?: boolean;
}

export interface SelectProps extends Omit<AriaSelectProps<any>, 'children'> {
  onSelectionChange?: (key: string) => void;
  items?: SelectItem[];
  defaultSelectedId?: string;
  defaultText?: string;
  className?: string;
  hasIconDivider?: boolean;
  label?: string;
  disabled?: boolean;
}

export const Select: FC<SelectProps> = ({
  onSelectionChange,
  defaultSelectedId,
  defaultText,
  className,
  hasIconDivider = true,
  label,
  disabled,
  items,
  ...props
}) => {
  const [selectedKey, setSelectedKey] = React.useState(defaultSelectedId);

  useEffect(() => {
    setSelectedKey(defaultSelectedId);
  }, [defaultSelectedId]);

  const handleChange = (key: any) => {
    const selected = key.currentKey;
    if (selected) {
      onSelectionChange?.(selected);
      setSelectedKey(selected);
    }
    overlayState.close();
  };

  // Get props for child elements from useSelect
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);
  const gridListRef = useRef(null);
  const overlayState = useOverlayTriggerState(props);
  // Create state based on the incoming props
  const state = useSelectState(props);
  const { labelProps, valueProps } = useSelect(props, state, buttonRef);
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, popoverRef);

  return (
    <div style={{ display: 'inline-block' }}>
      <SelectButton
        {...{ label, ...labelProps, ...valueProps, hasIconDivider, className, disabled, ...triggerProps }}
        ref={buttonRef}
        content={getSelectButtonText(defaultSelectedId, defaultText, selectedKey, items)()}
        onClick={overlayState.open}
      />
      {overlayState.isOpen && (
        <Popover
          {...overlayProps}
          triggerRef={buttonRef}
          popoverRef={popoverRef}
          state={overlayState}
          placement="bottom start"
        >
          <GridList
            ref={gridListRef}
            items={items as any[]}
            selectedKeys={[selectedKey]}
            onSelectionChange={handleChange}
            selectionMode="single"
            type="list"
          >
            {(items as any[])?.map((item) => <Item key={item.id}>{item.name}</Item>)}
          </GridList>
        </Popover>
      )}
    </div>
  );
};
