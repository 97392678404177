import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { PHONE_NUMBER, SUPPORT_EMAIL } from '@/constants';

import { SimpleFooter } from './SimpleFooter';
import logo from '@/assets/logoWhite.svg';
import truck from '@/assets/truck.svg';

const FooterTextBlock: React.FC<React.PropsWithChildren<{ title: React.ReactNode }>> = ({ title, children }) => (
  <div className="space-y-2">
    <div className="font-bold">{title}</div>

    {children}
  </div>
);

export const Footer: React.FC = () => (
  <>
    <div className="flex bg-darkBlue text-darkBlue-20 z-10 mt-auto">
      <div className="py-10 flex items-center gap-8 md:gap-20 w-full ml-4 md:ml-20 mr-auto text-sm max-w-7xl min-w-[663px]">
        <img src={logo} className="h-4 md:h-7" />

        <FooterTextBlock
          title={<FormattedMessage id={translations.application.footer.bigFooter.technicalSupport.title} />}
        >
          <div>
            <FormattedMessage
              id={translations.application.footer.bigFooter.technicalSupport.phone}
              values={{ phone: PHONE_NUMBER }}
            />
          </div>

          <div>
            <FormattedMessage
              id={translations.application.footer.bigFooter.technicalSupport.email}
              values={{ email: SUPPORT_EMAIL }}
            />
          </div>
        </FooterTextBlock>

        <FooterTextBlock title={<FormattedMessage id={translations.application.footer.bigFooter.openHours.title} />}>
          <div>
            <FormattedMessage id={translations.application.footer.bigFooter.openHours.interval1} />
          </div>

          <div>
            <FormattedMessage id={translations.application.footer.bigFooter.openHours.interval2} />
          </div>
        </FooterTextBlock>
      </div>
      <img src={truck} className="h-40 mr-20 my-4 hidden md:flex" />
    </div>

    <SimpleFooter />
  </>
);
