import React from 'react';

import { Select, SelectProps } from '@nshift/common/components/Select';

import { translations } from '@/locales';
import { useIntl } from 'react-intl';
import { ControlOption } from '@/domains';
import { entries } from 'lodash';

export interface Props extends Omit<SelectProps, 'items'> {
  value?: boolean | null;
  onChange?: (value: boolean) => void;
}

export const ControlOptionToValueMap: Record<ControlOption, any> = {
  [ControlOption.All]: null,
  [ControlOption.Blocked]: true,
  [ControlOption.Unblocked]: false
};

export const controlOptionsToNameMap: Record<ControlOption, string> = {
  [ControlOption.All]: translations.domains.clientControlOptions.ALL,
  [ControlOption.Blocked]: translations.domains.clientControlOptions.BLOCKED,
  [ControlOption.Unblocked]: translations.domains.clientControlOptions.UNBLOCKED
};

export const ClientControlOptionSelect: React.FC<Props> = ({ value, onChange, ...props }) => {
  const intl = useIntl();

  const controlOptions = Object.values(ControlOption).map((option) => ({
    id: String(ControlOptionToValueMap[option]),
    name: intl.formatMessage({ id: controlOptionsToNameMap[option] })
  }));

  return (
    <div>
      <Select
        {...props}
        defaultSelectedId={entries(ControlOptionToValueMap)?.find(([_, val]) => val === String(value))?.[0] ?? 'null'}
        onSelectionChange={(value: string) => {
          onChange?.(ControlOptionToValueMap[value as ControlOption]);
        }}
        items={controlOptions}
        label={intl.formatMessage({ id: translations.pages.clientSetup.blocked })}
      />
    </div>
  );
};
