import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

interface Props {
  button: React.ReactNode;
  className?: string;
}

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({ button, className, children }) => {
  return (
    <Menu as="div" className="relative h-full">
      <Menu.Button as={React.Fragment}>{button}</Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'absolute flex flex-col overflow-hidden bg-white divide-y rounded-md shadow-lg min-w-min divide-darkBlue-10 ring-1 ring-black ring-opacity-5 focus:outline-none z-50',
            className
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface DropdownMenuItemProps {
  onClick?(): void;
  active?: boolean;
  className?: string;
  linkTo?: string;
}

export const DropdownMenuItem: React.FC<React.PropsWithChildren<DropdownMenuItemProps>> = ({
  onClick,
  active,
  className,
  children,
  linkTo
}) => (
  <Menu.Item
    as="div"
    onClick={onClick}
    className={classNames(
      'hover:bg-highlightingBlue-5 transition-colors flex',
      active && 'bg-highlightingBlue-5',
      className
    )}
  >
    {linkTo ? (
      <NavLink className="flex-1 px-6 py-4" to={linkTo}>
        {children}
      </NavLink>
    ) : (
      <div className="flex-1 px-6 py-4">{children}</div>
    )}
  </Menu.Item>
);
