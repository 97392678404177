import React from 'react';
import classNames from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';

interface Props {
  url: string;
  isLink?: boolean;
  className?: string;
}

export const HeaderItem: React.FC<React.PropsWithChildren<Props>> = ({ url, isLink = false, className, children }) => {
  const location = useLocation();

  const isActive = !!matchPath(location.pathname, url) || location.pathname.startsWith(url);

  const baseStyle =
    'flex items-center justify-center h-full px-2 font-semibold transition-all duration-300 border-b-2 cursor-pointer hover:border-internationalBlue';

  return isLink ? (
    <Link
      className={classNames(baseStyle, isActive ? 'border-internationalBlue' : 'border-transparent', className)}
      to={url}
    >
      {children}
    </Link>
  ) : (
    <div className={classNames(baseStyle, isActive ? 'border-internationalBlue' : 'border-transparent', className)}>
      {children}
    </div>
  );
};
