import { apis, localURLs } from '@/constants/apis';

export const getBaseURL = (): string => {
  const localURL = location.origin;

  if (localURL === localURLs.PP) {
    return apis.PP;
  }

  if (localURL === localURLs.PROD) {
    return apis.PROD;
  }

  return apis.QA;
};
