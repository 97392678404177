import * as React from 'react';
import { FC } from 'react';
// import { ActionButton } from './GridRowActionButtonsMenu';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface Props {
  bulkActionButtons: any[];
  selectedRowIds: number[] | string[];
  onClose: () => void;
}

export const TableBulkActionsBar: FC<Props> = ({ bulkActionButtons, onClose, selectedRowIds }) => {
  const selectedRowsCount = React.useMemo(() => selectedRowIds.length, [selectedRowIds]);

  return (
    <div className="h-16 sticky bottom-8 z-10 flex items-center bg-highlightingBlue rounded-lg self-center px-2.5 min-w-[700px] m-auto w-fit">
      <div onClick={onClose}>
        <Icon type="close" appearance="white" className="px-2" aria-hidden="true" />
      </div>
      <div className="text-white">{selectedRowsCount} selected</div>
      <div className="ml-auto flex gap-2">
        {bulkActionButtons.map(({ id, label, handlerFn }) => (
          <Button
            key={id}
            onClick={() => {
              handlerFn(selectedRowIds);
            }}
            appearance="secondary"
          >
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};
