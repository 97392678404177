import React, { FC, useRef } from 'react';
import { AriaCheckboxProps, useCheckbox, useGridListSelectionCheckbox } from 'react-aria';
import { ListState, useToggleState } from 'react-stately';

interface Props {
  item: any;
  state: ListState<any>;
}

export const GridListCheckbox: FC<Props> = ({ item, state }) => {
  const { checkboxProps } = useGridListSelectionCheckbox({ key: item.key }, state);
  return <Checkbox className="m-0" type="checkbox" {...checkboxProps} />;
};

interface CheckboxProps extends AriaCheckboxProps {
  className?: string;
  type: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const inputRef = useRef(null);
  const { inputProps } = useCheckbox(props, useToggleState(props), inputRef);
  return <input className="mr-2" {...inputProps} ref={inputRef} />;
};
