import React, { FC } from 'react';
import classnames from 'classnames';
import { TableRowActionsMenu } from './TableRowActionsMenu';
import { checkboxColumn } from '../constants';
import { BulkActionButton, RowActionButton } from '../types';
import { flexRender, Table } from '@tanstack/react-table';

interface Props {
  table: Table<Record<string, unknown>>;
  bulkActionButtons?: BulkActionButton[];
  rowActionButtons?: RowActionButton[];
  trClassName?: string;
  flexRender: typeof flexRender;
  onRowClick?: (row: Record<string, unknown>) => void;
}

export const TableRows: FC<Props> = ({
  table,
  trClassName,
  bulkActionButtons,
  rowActionButtons,
  flexRender,
  onRowClick
}) => {
  return (
    <>
      {table.getRowModel().rows.map((row) => {
        return (
          <tr
            key={row.id}
            className={classnames(
              'h-12 text-xs text-darkBlue-70 border-b border-internationalBlue-5',
              'hover:bg-internationalBlue-5 hover:text-highlightingBlue hover:border-collapse',
              trClassName
            )}
            onClick={() => onRowClick?.(row.original)}
          >
            {bulkActionButtons.length > 0 ? (
              <td key="check">{checkboxColumn.cell({ row })}</td>
            ) : (
              <td className="w-5" />
            )}
            {row.getVisibleCells().map((cell) => {
              return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
            })}
            {rowActionButtons.length > 0 && (
              <td>
                <TableRowActionsMenu
                  row={row.original}
                  actionButtons={rowActionButtons}
                  openDetailsDrawerFn={() => {
                    onRowClick?.(row.original);
                  }}
                />
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};
