import React, { FC, forwardRef } from 'react';
import { Icon } from '../Icon';
import classnames from 'classnames';
import { labelHalfFillBackground } from './constants';

interface ButtonProps extends HTMLButtonElement {
  hasIconDivider?: boolean;
  onClick: () => void;
  valueProps: any;
  content?: string;
  labelProps?: any;
  label?: string;
}

export const SelectButton: FC<ButtonProps> = forwardRef(
  ({ className, hasIconDivider, valueProps, content, labelProps, label, disabled, onClick }, ref) => {
    return (
      <div className="relative w-fit group">
        <div
          {...labelProps}
          className={classnames(
            'relative z-20 pointer-events-none h-fit w-fit top-[8px] left-2',
            'text-ellipsis overflow-hidden whitespace-nowrap select-none',
            'text-xs text-darkBlue-60',
            labelHalfFillBackground(disabled)
          )}
        >
          {label}
        </div>
        <button
          ref={ref}
          id="dropdown-button"
          data-dropdown-toggle="dropdown"
          className={classnames(
            'h-10 flex items-center px-4 gap-2',
            'text-sm text-darkBlue-70',
            'border rounded-lg',
            disabled ? 'border-internationalBlue-20' : 'border-darkBlue-30',
            disabled
              ? 'bg-highlightingBlue-3 text-darkBlue-40 pointer-events-none'
              : 'bg-white hover:bg-internationalBlue-5 hover:border-highlightingBlue focus:border-highlightingBlue',
            className
          )}
          onClick={onClick}
        >
          <span {...valueProps}>{content}</span>
          <div className={classnames('flex justify-end ml-auto', hasIconDivider && 'border-l border-l-darkBlue-30')}>
            <Icon type="chevronDownAlt" sizeClassName="text-sm" className="pl-1" aria-hidden="true" />
          </div>
        </button>
      </div>
    );
  }
);
