import React from 'react';

import classNames from 'classnames';
import { useDropIndicator } from 'react-aria';

export const DropIndicator = (props) => {
  const ref = React.useRef(null);
  const { dropIndicatorProps, isHidden, isDropTarget } = useDropIndicator(props, props.dropState, ref);

  if (isHidden) {
    return null;
  }
  // Render a drop indicator for the item. This is the height of the dragged item.
  return (
    <li
      {...dropIndicatorProps}
      ref={ref}
      role="option"
      className={classNames('h-11', 'transition-all duration-200', isDropTarget ? 'drop-target' : '')}
    />
  );
};
