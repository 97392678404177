import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { passwordSchema } from '@/schemas';

import { Button } from '@nshift/common/components/Button';
import { FormInput } from '@nshift/common/components/Input/FormInput';

import { Session } from '@/containers/SessionContainer';
import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';

const schema = yup.object().shape({
  username: yup.string().required().label(translations.inputs.email.label),
  password: passwordSchema
});

export const SignInPage: React.FC = () => {
  const intl = useIntl();

  const { loginWithEmailAndPassword, error } = Session.useContainer();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema, {})
  });

  const onSubmit = handleSubmit((data) => loginWithEmailAndPassword(data));

  useEffect(() => {
    if (error) {
      setError('password', { message: intl.formatMessage({ id: translations.pages.signIn.invalidCredentials }) });
    }
  }, [error]);

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.signIn.title} />
      </div>

      <div className="mt-2 text-sm text-darkBlue-50">
        <FormattedMessage
          id={translations.pages.signIn.signUpRedirect}
          values={{
            span: ((text: string) => (
              <Link to={urls.signUp} className="underline text-orange-light">
                {text}
              </Link>
            )) as unknown as React.ReactNode
          }}
        />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col mt-6">
        <FormInput
          errors={errors}
          id="username"
          name="username"
          label={intl.formatMessage({ id: translations.inputs.userName.label })}
          register={register}
          fullWidth
        />

        <FormInput
          errors={errors}
          type="password"
          id="password"
          name="password"
          label={intl.formatMessage({ id: translations.inputs.password.label })}
          register={register}
          className="mt-4"
          fullWidth
        />

        <Button
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          appearance="primary"
          size="medium"
          className="mt-10"
        >
          <FormattedMessage id={translations.inputs.buttons.signIn} />
        </Button>

        <Link to={urls.forgotPassword} className="mt-4 text-internationalBlue">
          <FormattedMessage id={translations.pages.signIn.forgotPasswordRedirect} />
        </Link>

        <PrivacyNotice />
      </form>
    </>
  );
};
