import React, { FC } from 'react';
import classnames from 'classnames';
import { AriaSwitchProps } from 'react-aria';

interface Props extends AriaSwitchProps {
  id?: string;
  label?: string;
  name: string;
  register: any;
  labelClassName?: string;
  isDisabled?: boolean;
}

export const FormToggle: FC<Props> = ({ register, name, id, label, labelClassName, isDisabled }) => {
  return (
    <div className="flex items-center">
      <label className="relative inline-flex items-center cursor-pointer">
        <input {...{ ...register(name), id }} type="checkbox" className="sr-only peer" />
        <div
          className={classnames(
            'peer h-6 w-12 rounded-full border relative',
            isDisabled
              ? 'bg-darkBlue-30 peer-checked:bg-highlightingBlue-40'
              : 'bg-darkBlue peer-checked:bg-highlightingBlue',
            'after:absolute after:left-[0.125rem] after:top-1/2 after:transform after:-translate-y-1/2 after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:border-darkBlue-10 after:bg-white after:transition-all after:content-[""]',
            'peer-checked:after:translate-x-full peer-checked:after:left-[calc(100%-2.39rem)]'
          )}
        />
        <label className={classnames('ml-2 text-internationalBlue', labelClassName)} htmlFor={id}>
          {label}
        </label>
      </label>
    </div>
  );
};
