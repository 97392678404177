import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, AxiosError } from 'axios';

export enum Header {
  //Some example headers
  AcceptLanguage = 'accept-language',
  Authorization = 'authorization',
  Platform = 'x-app-platform',
  Type = 'x-app-type',
  Version = 'x-app-version'
}

interface ErrorReporting {
  disabled?: boolean;
  ignoreStatus?: number[];
  reportError: (error: HttpError<any>) => void;
}

export interface HttpPromise<T, C extends HttpRequestConfig = HttpRequestConfig> extends Promise<HttpResponse<T, C>> {}

export interface HttpInstance<C extends HttpRequestConfig = HttpRequestConfig> extends AxiosInstance {
  (config: C): HttpPromise<any, C>;
  (url: string, config?: C): HttpPromise<any, C>;
}

export interface HttpRequestConfig extends InternalAxiosRequestConfig {
  errorReporting?: ErrorReporting;
}

export interface HttpResponse<T, C extends HttpRequestConfig = HttpRequestConfig> extends AxiosResponse<T> {
  config: C;
}

export interface HttpError<T, C extends HttpRequestConfig = HttpRequestConfig> extends AxiosError<T> {
  config: C;
  response?: HttpResponse<T, C>;
}

export class HttpClient<C extends HttpRequestConfig = HttpRequestConfig> {
  protected client: HttpInstance<C>;

  constructor(config?: C) {
    this.client = axios.create(config) as HttpInstance<C>;

    this.client.interceptors.request.use(undefined, this.report);
    this.client.interceptors.response.use(undefined, this.report);
  }

  private shouldReport(errorReporting?: ErrorReporting, response?: HttpResponse<C>) {
    if (!errorReporting) return true;

    if (errorReporting.disabled || !response) return false;

    if (!errorReporting.ignoreStatus) return true;

    return !errorReporting.ignoreStatus.includes(response.status);
  }

  protected getReportOptions(error: HttpError<any>) {
    return error.isAxiosError
      ? {
          config: {
            headers: error.config.headers,
            data: error?.config?.data,
            params: error.config.params
          },
          response: error.response ? error?.response?.data : {}
        }
      : {};
  }

  private report = (error: HttpError<any>) => {
    const shouldReport =
      this.shouldReport((this.client.defaults as unknown as HttpRequestConfig).errorReporting, error.response) &&
      this.shouldReport(error.config.errorReporting, error.response);

    if (!shouldReport) throw error;

    error.config.errorReporting?.reportError(error);

    throw error;
  };

  protected unwrap = <T>(response: HttpResponse<T>) => response?.data;

  get<T>(url: string, params?: object, config?: C) {
    return this.client.get<T>(url, { params, ...config }).then(this.unwrap);
  }

  post<T>(url: string, data?: object, config?: C) {
    return this.client.post<T>(url, data, config).then(this.unwrap);
  }

  put<T>(url: string, data?: object, config?: C) {
    return this.client.put<T>(url, data, config).then(this.unwrap);
  }

  patch<T>(url: string, data?: object, config?: C) {
    return this.client.patch<T>(url, data, config).then(this.unwrap);
  }

  delete<T>(url: string, config?: C) {
    return this.client.delete<T>(url, config).then(this.unwrap);
  }
}
