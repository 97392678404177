import React from 'react';

import { useProgressBar, AriaProgressBarProps } from 'react-aria';
import classNames from 'classnames';

import { SpinnerColor, LoadingSize } from './contants';

export interface SpinnerProps extends AriaProgressBarProps {
  size: LoadingSize;
  fillColor: SpinnerColor;
  showPercentage?: boolean;
  isLoader?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  size = LoadingSize.Medium,
  fillColor = SpinnerColor.Primary,
  showPercentage,
  isLoader,
  minValue = 0,
  maxValue = 100,
  value,
  label,
  isIndeterminate,
  ...props
}) => {
  const { progressBarProps } = useProgressBar({
    minValue,
    maxValue,
    value,
    label,
    isIndeterminate,
    ...props
  });

  const sizeMap: Record<LoadingSize, number> = {
    [LoadingSize.Small]: 16,
    [LoadingSize.Medium]: 48,
    [LoadingSize.Large]: 80
  };

  const sizeToStrokeWidthMap: Record<LoadingSize, number> = {
    [LoadingSize.Small]: 3,
    [LoadingSize.Medium]: 2.5,
    [LoadingSize.Large]: 3
  };

  const textTopOffsetMap: Record<LoadingSize, string> = {
    [LoadingSize.Small]: 'top-[5px]',
    [LoadingSize.Medium]: 'top-[16px]',
    [LoadingSize.Large]: 'top-[32px]'
  };

  const percentageTextSizeMap: Record<LoadingSize, string> = {
    [LoadingSize.Small]: '',
    [LoadingSize.Medium]: 'text-xs',
    [LoadingSize.Large]: ''
  };

  const spinnerColorMap: Record<SpinnerColor, string> = {
    [SpinnerColor.Disabled]: 'stroke-darkBlue-20',
    [SpinnerColor.Primary]: 'stroke-internationalBlue'
  };

  const center = 16;
  const radius = center - sizeToStrokeWidthMap[size];
  const circumference = 2 * radius * Math.PI;
  const percentage = isIndeterminate ? 0.75 : (value - minValue) / (maxValue - minValue);
  const offset = circumference - percentage * circumference;

  const textTopOffset = textTopOffsetMap[size] || textTopOffsetMap[LoadingSize.Medium];
  const percentageTextSize = percentageTextSizeMap[size] || percentageTextSizeMap[LoadingSize.Medium];

  return (
    <div className="relative flex w-fit justify-center align-middle">
      <svg
        {...progressBarProps}
        width={sizeMap[size]}
        height={sizeMap[size]}
        viewBox="0 0 32 32"
        fill="none"
        strokeWidth={sizeToStrokeWidthMap[size]}
      >
        {isLoader && (
          <circle role="presentation" cx={center} cy={center} r={radius} className="stroke-internationalBlue-10" />
        )}
        <circle
          className={spinnerColorMap[fillColor]}
          role="presentation"
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset || 0}
          strokeLinecap="round"
          transform="rotate(-90 16 16)"
        >
          {isIndeterminate && (
            <animateTransform
              attributeName="transform"
              type="rotate"
              begin="0s"
              dur="1s"
              from="0 16 16"
              to="360 16 16"
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;1"
              keySplines="0.7 0.3 0.3 0.7"
            />
          )}
        </circle>
      </svg>
      {showPercentage && size !== LoadingSize.Small && (
        <div className={classNames('text-darkBlue absolute z-10', textTopOffset, percentageTextSize)}>
          {progressBarProps['aria-valuetext']}
        </div>
      )}
    </div>
  );
};
