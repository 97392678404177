import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { NumberField } from './NumberField';

interface FormNumberFieldProps extends React.ComponentProps<typeof NumberField> {
  control: Control<FieldValues>;
}

export const FormNumberField: React.FC<FormNumberFieldProps> = ({ name, control, required, ...rest }) => {
  const { field } = useController({
    name,
    control
  });

  return (
    <NumberField
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      required={required}
      {...rest}
    />
  );
};
