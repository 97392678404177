import React from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { Icon } from '@nshift/common/components/Icon';
import { Session } from '@/containers/SessionContainer';

import { SlimHeader } from './SlimHeader';
import { HeaderItem } from './HeaderItem';
import { Dropdown, DropdownMenuItem } from './HeaderDropdown';

import logo from '@/assets/logo.svg';
import { UserImage } from '../objects/UserImage';

export const Header: React.FC = () => {
  const { logout, me } = Session.useContainer();

  const isProduction = process.env.NODE_ENV !== 'development';

  return (
    <div className="fixed inset-x-0 top-0 z-10">
      <SlimHeader />

      <div className="flex justify-between h-[72px] bg-white shadow items-center px-8">
        <Link to={urls.overview} className="mr-4">
          <img src={logo} className="h-10" />
        </Link>

        <div className="flex items-center w-full h-full space-x-8 max-w-7xl text-internationalBlue">
          {!isProduction && (
            <HeaderItem url={urls.overview} isLink>
              <FormattedMessage id={translations.application.header.overview} />
            </HeaderItem>
          )}

          {!isProduction && (
            <HeaderItem url={urls.dashboard} isLink>
              <FormattedMessage id={translations.application.header.dashboard} />
            </HeaderItem>
          )}

          {!isProduction && (
            <HeaderItem url={urls.setUp.base}>
              <Dropdown
                button={
                  <div className="flex items-center justify-center h-full text-internationalBlue">
                    <FormattedMessage id={translations.application.header.setUp.title} />

                    <Icon
                      appearance="blue"
                      type="chevronDown"
                      sizeClassName="text-[16px] leading-[16px]"
                      className="ml-2"
                      aria-hidden="true"
                    />
                  </div>
                }
              >
                <DropdownMenuItem linkTo={urls.setUp.clients}>
                  <FormattedMessage id={translations.application.header.setUp.clients} />
                </DropdownMenuItem>

                <DropdownMenuItem linkTo={urls.setUp.printers}>
                  <FormattedMessage id={translations.application.header.setUp.printers} />
                </DropdownMenuItem>
              </Dropdown>
            </HeaderItem>
          )}

          {/* <HeaderItem url={urls.printers.base} isLink>
            <FormattedMessage id={translations.application.header.printerProfiles} />
          </HeaderItem> */}

          {!isProduction && (
            <HeaderItem url={urls.printers.base}>
              <Dropdown
                button={
                  <div className="flex items-center justify-center h-full text-internationalBlue">
                    <FormattedMessage id={translations.application.header.printers} />

                    <Icon
                      appearance="blue"
                      type="chevronDown"
                      sizeClassName="text-[16px] leading-[16px]"
                      className="ml-2"
                      aria-hidden="true"
                    />
                  </div>
                }
              >
                <DropdownMenuItem linkTo={urls.printers.profiles.base}>
                  <FormattedMessage id={translations.application.header.printerProfiles} />
                </DropdownMenuItem>
              </Dropdown>
            </HeaderItem>
          )}

          {!isProduction && (
            <HeaderItem url={urls.printJobs.base} isLink>
              <FormattedMessage id={translations.application.header.printJobs} />
            </HeaderItem>
          )}

          {isProduction && (
            <HeaderItem url={urls.printers.profiles.base} isLink>
              <FormattedMessage id={translations.application.header.printerProfilesFull} />
            </HeaderItem>
          )}
        </div>

        {me && (
          <div className="pl-3 transition-colors border rounded-full hover:bg-highlightingBlue-10 hover:cursor-pointer">
            <Dropdown
              className="right-0 mt-2"
              button={
                <div className="flex items-center space-x-3">
                  <div className="text-nowrap">{me.companyName}</div>

                  <div>
                    <UserImage me={me} rounded />
                  </div>
                </div>
              }
            >
              <Menu.Item as="div" onClick={logout} className="px-6 py-4 cursor-pointer">
                <FormattedMessage id={translations.application.header.profile.logout} />
              </Menu.Item>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};
