import { Renderable, ValueOrFunction } from 'react-hot-toast/headless';
import { Appearance as IconAppearance, Type as IconType } from '@nshift/common/components/Icon';

export enum ToastType {
  Success = 'success',
  Error = 'error'
  // Warning = 'warning',
  // Info = 'info'
}

export interface ToastDetails {
  background: string;
  text: string;
  topBorder: string;
  iconAppearance: IconAppearance;
  iconType: IconType;
}

export interface ToastProps {
  type: ToastType;
  // other normal Toast options
  duration?: number;
  role: 'status' | 'alert';
  ariaLive: 'assertive' | 'off' | 'polite';
  //extra props meant for the Alert component we are rendering
  solid?: boolean;
}
export type ToastOptions = Partial<Omit<ToastProps, 'height' | 'message' | 'pauseDuration' | 'visible'>>;
export type DefaultToastOptions = ToastOptions & {
  [key in ToastType]?: ToastOptions;
};

export type ToastMessage = ValueOrFunction<Renderable, Toast>;
export type ToastHandler = (message: ToastMessage, options?: ToastOptions) => string;
