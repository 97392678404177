import * as React from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { translations } from '@/locales';
import { DialogOpenFn, urls } from '@/constants';
import { deletePrinterProfile } from '@/services/api';

import { toast } from '@nshift/common/components/Toast';
import { TableConfig } from '@nshift/common/components/Table';

import { PrinterProfileStatusBadge } from './PrinterProfileStatusBadge';
import { PrinterProfileDialogs, PrinterProfileStatus } from './types';

export const getStatus = (row: Record<string, unknown>) => {
  return row.active ? PrinterProfileStatus.Active : PrinterProfileStatus.Inactive;
};

type PrinterProfilesTableHook = (
  params?: { onDialogOpen?: DialogOpenFn<PrinterProfileDialogs> } | undefined
) => TableConfig;

export const usePrinterProfileConfig: PrinterProfilesTableHook = ({ onDialogOpen } = {}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  console.log({ onDialogOpen });
  const rowActionButtons: any[] = [
    {
      id: 'edit',
      label: intl.formatMessage({ id: translations.application.buttons.edit }),
      handlerFn: (row: any) => {
        navigate(urls.printers.getDetailUrl(row?.id?.toString()));
      }
    },
    // {
    //   id: 'duplicate',
    //   label: translateFn({ id: translations.application.buttons.duplicate }),
    //   handlerFn: (rowId) => {
    //     onDialogOpen({ name: PrinterProfileDialogs.DUPLICATE, rowIds: [rowId] });
    //   }
    // },
    {
      id: 'delete',
      label: intl.formatMessage({ id: translations.application.buttons.delete }),
      handlerFn: (row: any) => {
        deletePrinterProfile(row.id)
          .then(() => {
            toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.deleteSuccess }));
            queryClient.invalidateQueries('get-printer-profiles');
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.deleteError }));
          });
      }
    }
  ];

  const colsConfig: any[] = [
    {
      accessorKey: 'id',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.id })
    },
    {
      accessorKey: 'name',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.name })
    },
    {
      accessorKey: 'active',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.status }),
      cell: (info: any) => <PrinterProfileStatusBadge status={getStatus(info.row.original)} />
    },
    {
      accessorKey: 'clientName',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.client })
    },
    {
      accessorKey: 'printerProfileGroups',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.profileGroups }),
      cell: (info: any) => {
        const groupsToString = info
          .getValue()
          .map(({ id, name }: { [key: string]: string }) => `${id} - ${name}`)
          .join(',');
        const groupsShortened = groupsToString.length > 50 ? `${groupsToString.slice(0, 50)}...` : groupsToString;
        return <div>{groupsShortened}</div>;
      }
    },
    {
      accessorKey: 'printerName',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer })
    },
    {
      accessorKey: 'format',
      header: intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format })
    },
    {
      accessorKey: 'media',
      header: intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media })
    }
  ];

  return {
    colsConfig,
    rowActionButtons
  };
};
