import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { getPrinterProfiles } from '@/services/api';

import { Button } from '@nshift/common/components/Button';
import { Table } from '@nshift/common/components/Table';

import { usePrinterProfileConfig } from './PrinterProfilesTableConfig';
import { PrinterProfileDetailsView } from './PrinterProfileDetailsView';

const PrinterProfilesPage: React.FC = () => {
  const intl = useIntl();
  const { data: printerProfiles = [], isLoading } = useQuery<any>('get-printer-profiles', getPrinterProfiles);

  const { colsConfig, rowActionButtons } = usePrinterProfileConfig();

  return (
    <div className="w-full py-20">
      <div className="flex items-center justify-between px-6 mb-6">
        <h1 className="text-3xl font-semibold">
          <FormattedMessage id={translations.pages.printerProfiles.title} />
        </h1>

        <Button className="w-52" appearance="primary" linkTo={urls.printers.profiles.create}>
          <FormattedMessage id={translations.pages.printerProfiles.buttons.addProfile} />
        </Button>
      </div>

      <div className="flex flex-col mx-6 bg-white rounded-lg shadow">
        <Table
          id="PrinterProfilesTable"
          data={printerProfiles}
          columns={colsConfig}
          rowActionButtons={rowActionButtons}
          drawerActionButtons={rowActionButtons}
          noResultsTitle={intl.formatMessage(
            { id: translations.miscellaneous.table.empty.title },
            { items: intl.formatMessage({ id: translations.pages.printerProfiles.items }) }
          )}
          noResultsMessage={intl.formatMessage({ id: translations.miscellaneous.table.empty.message })}
          loading={isLoading}
          renderRowDetailsSection={(row: any) => <PrinterProfileDetailsView row={row} />}
        />
      </div>
    </div>
  );
};

export default PrinterProfilesPage;
