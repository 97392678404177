export enum LoadingColor {
  Disabled = 'disabled',
  Primary = 'primary'
}

export enum LoadingSize {
  Small = 'h-1',
  Medium = 'h-2',
  Large = 'h-4'
}

export enum SpinnerColor {
  Disabled = 'disabled',
  Primary = 'primary'
}