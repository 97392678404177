import React from 'react';

import { Dropdown, DropdownMenuItem } from './HeaderDropdown';

import { Icon } from '@nshift/common/components/Icon';

import { LanguageContainer } from '../Context/LanguageContext';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locales';
import { SUPPORT_URL, WEBSHOP_URLS } from '@/constants/externalUrls';

export const SlimHeader: React.FC = () => {
  const { language, languages, update } = LanguageContainer.useContainer();

  const isProduction = process.env.NODE_ENV !== 'development';

  return (
    <div className="relative z-20 flex items-center w-full h-8 px-8 text-sm divide-x bg-darkBlue-5 divide-darkBlue">
      <a href={WEBSHOP_URLS.EN} className="px-2 ml-auto">
        <FormattedMessage id={translations.application.header.slimHeader.webshop} />
      </a>

      <a href={SUPPORT_URL} className="px-2">
        <FormattedMessage id={translations.application.header.slimHeader.support} />
      </a>

      <div className="pl-2">
        {isProduction ? (
          <div className="flex items-center">EN</div>
        ) : (
          <Dropdown
            className="right-0 "
            button={
              <div className="flex items-center cursor-pointer">
                {language.toUpperCase()}
                <Icon
                  type="chevronDown"
                  appearance="none"
                  className="ml-1 "
                  sizeClassName="text-[10px] leading-[10px]"
                />
              </div>
            }
          >
            {languages.map((languageItem) => (
              <DropdownMenuItem
                key={languageItem}
                active={language === languageItem}
                onClick={() => update(languageItem)}
              >
                <div>{languageItem.toUpperCase()}</div>
              </DropdownMenuItem>
            ))}
          </Dropdown>
        )}
      </div>
    </div>
  );
};
