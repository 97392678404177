import { PrinterOption, PrinterOptionKey } from './types';

export const mockPrinterOptions: PrinterOption[] = [
  {
    key: PrinterOptionKey.Bins,
    defaultValue: null,
    values: []
  },
  {
    key: PrinterOptionKey.Collate,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Color,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Dpi,
    defaultValue: null,
    values: []
  },
  {
    key: PrinterOptionKey.Duplex,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Nup,
    defaultValue: 1,
    values: []
  }
];
