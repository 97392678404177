export enum PrintJobStatus {
  Pending = 'PENDING',
  Error = 'ERROR',
  Printed = 'PRINTED',
  Cancelled = 'CANCELLED'
}

export enum AvailablePrintJobStatus {
  Resent = 'RESENT',
  Cancelled = 'CANCELLED',
  Received = 'RECEIVED',
  Sent = 'SENT',
  Failed = 'FAILED',
  Printed = 'PRINTED',
  Registered = 'REGISTERED',
  ReadyForPrinting = 'READY_FOR_PRINTING',
  Expired = 'EXPIRED',
  FailedToSend = 'FAILED_TO_SEND'
}

export const statusMapping: Record<AvailablePrintJobStatus, PrintJobStatus> = {
  [AvailablePrintJobStatus.Resent]: PrintJobStatus.Pending,
  [AvailablePrintJobStatus.Received]: PrintJobStatus.Pending,
  [AvailablePrintJobStatus.Sent]: PrintJobStatus.Pending,
  [AvailablePrintJobStatus.Failed]: PrintJobStatus.Error,
  [AvailablePrintJobStatus.Printed]: PrintJobStatus.Printed,
  [AvailablePrintJobStatus.Registered]: PrintJobStatus.Pending,
  [AvailablePrintJobStatus.ReadyForPrinting]: PrintJobStatus.Pending,
  [AvailablePrintJobStatus.Expired]: PrintJobStatus.Error,
  [AvailablePrintJobStatus.FailedToSend]: PrintJobStatus.Error,
  [AvailablePrintJobStatus.Cancelled]: PrintJobStatus.Cancelled
};

export const getSubStatuses = (statuses: PrintJobStatus[]): AvailablePrintJobStatus[] => {
  const substatuses = [] as AvailablePrintJobStatus[];
  statuses.forEach((status) => {
    for (const [key, value] of Object.entries(statusMapping)) {
      if (value === status) {
        substatuses.push(key as AvailablePrintJobStatus);
      }
    }
  });
  return substatuses;
};

export const printJobsFilters = [
  { value: 'printer', name: 'Printer' },
  { value: 'printId', name: 'Print Id' },
  { value: 'platformObjectId', name: 'Object Id' },
  { value: 'reference', name: 'Reference' },
  { value: 'statuses', name: 'Status' },
  { value: 'dateFrom', name: 'From' },
  { value: 'dateTo', name: 'To' }
];

export enum PrintJobDialogs {
  Duplicate = 'DUPLICATE',
  Resend = 'RESEND',
  Delete = 'DELETE'
}

export enum PrintJobsActionTypes {
  Cancel = 'CANCEL',
  Resend = 'RESEND',
  Duplicate = 'DUPLICATE'
}
