import React from 'react';
import { Badge, Appearance as BadgeAppearance } from '@nshift/common/components/Badge';
import { Icon, IconAppearance, Type as IconType } from '@nshift/common/components/Icon';

import { ClientStatus } from '@/domains';
import { useIntl } from 'react-intl';
import { translations } from '@/locales';
import classnames from 'classnames';

interface StatusBadgeDetails {
  appearance: BadgeAppearance;
  iconAppearance: IconAppearance;
  iconType: IconType;
}

const clientStatusBadgeDetails: Record<ClientStatus, StatusBadgeDetails> = {
  [ClientStatus.Connected]: {
    appearance: 'green',
    iconAppearance: 'green',
    iconType: 'confirmed'
  },
  [ClientStatus.Disconnected]: {
    appearance: 'red',
    iconAppearance: 'red',
    iconType: 'stop'
  },
  [ClientStatus.OutOfPaper]: {
    appearance: 'yellow',
    iconAppearance: 'yellow',
    iconType: 'stop'
  }
};

export const ClientStatusBadge: React.FC<{ status: ClientStatus }> = ({ status }) => {
  const badge = clientStatusBadgeDetails[status] ?? clientStatusBadgeDetails[ClientStatus.Disconnected];
  return (
    <Badge className="w-fit" appearance={badge.appearance} clickable={false}>
      <Icon
        type={badge.iconType}
        appearance={badge.iconAppearance}
        sizeClassName="text-xs"
        className="pr-2"
        aria-hidden="true"
      />
      <span className="mr-1">{status ?? ClientStatus.Disconnected}</span>
    </Badge>
  );
};

export const ClientControlBadge: React.FC<{ blocked: boolean }> = ({ blocked }) => {
  const intl = useIntl();
  const texts = translations.pages.clientSetup;
  return (
    <Badge
      className={classnames(blocked ? 'bg-warningRed-30' : 'bg-internationalBlue-5 text-darkBlue-60', 'w-fit')}
      clickable={false}
    >
      {blocked ? intl.formatMessage({ id: texts.blocked }) : intl.formatMessage({ id: texts.unblocked })}
    </Badge>
  );
};
