import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { passwordSchema } from '@/schemas';
import { activatePassword, checkToken } from '@/services/api';

import { Button } from '@nshift/common/components/Button';
import { FormInput } from '@nshift/common/components/Input/FormInput';

import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';

const schema = yup.object().shape({
  password: passwordSchema,
  passwordConfirmation: passwordSchema.oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch)
});

export const ActivatePage: React.FC = () => {
  const intl = useIntl();

  const { search } = useLocation();

  const token = new URLSearchParams(search).get('token') ?? '';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting }
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema, {})
  });

  const onSubmit = handleSubmit((data) => {
    checkToken(token).then(() => activatePassword(data.password, token));
  });

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.activate.title} />
      </div>

      <div className="mt-4 text-sm text-darkBlue-50">
        <FormattedMessage id={translations.pages.activate.subtitle} />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col w-full mt-10">
        <FormInput
          fullWidth
          errors={errors}
          type="password"
          id="password"
          name="password"
          label={intl.formatMessage({ id: translations.inputs.newPassword.label })}
          register={register}
        />

        <FormInput
          fullWidth
          errors={errors}
          type="password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label={intl.formatMessage({ id: translations.inputs.newPasswordConfirmation.label })}
          register={register}
          className="mt-4"
        />

        <div className="flex items-center h-10">
          {isSubmitSuccessful && (
            <div className="text-base text-confirmingGreen">
              <FormattedMessage
                id={translations.pages.activate.accountActivated}
                values={{
                  a: ((text: string) => (
                    <Link to={urls.signIn} className="underline text-internationalBlue">
                      {text}
                    </Link>
                  )) as unknown as React.ReactNode
                }}
              />
            </div>
          )}
        </div>

        <Button
          disabled={isSubmitting || isSubmitSuccessful}
          isLoading={isSubmitting}
          type="submit"
          appearance="primary"
          size="medium"
        >
          <FormattedMessage id={translations.inputs.buttons.confirmPassword} />
        </Button>

        <PrivacyNotice />
      </form>
    </>
  );
};
