import React, { FC, useRef } from 'react';
import { mergeProps, useFocusRing, useGridListItem } from 'react-aria';

import { ListState } from 'react-stately';
import classnames from 'classnames';
import { GridListCheckbox } from './GridListCheckbox';
interface Props {
  item: any;
  state: ListState<any>;
  type: 'checkboxItem' | 'item';
}

export const GridListItem: FC<Props> = ({ type, item, state }) => {
  const ref = useRef(null);
  const renderedItemRef = useRef(null);
  const { rowProps, gridCellProps, isFocused, isPressed } = useGridListItem({ node: item }, state, ref);

  const { isFocusVisible, focusProps } = useFocusRing();

  const showCheckbox =
    type === 'checkboxItem' &&
    state.selectionManager.selectionMode !== 'none' &&
    state.selectionManager.selectionBehavior === 'toggle';

  return (
    <li
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
      id={item.id}
      className={classnames(
        'cursor-pointer rounded hover:bg-internationalBlue-10 text-darkBlue',
        isPressed && 'pressed',
        isFocusVisible && 'focus-visible',
        isFocused ? 'focus:bg-internationalBlue-10 outline-highlightingBlue' : 'bg-white outline-none'
      )}
    >
      <div {...gridCellProps} ref={renderedItemRef}>
        {showCheckbox && <GridListCheckbox item={item} state={state} />}
        {item.rendered}
      </div>
    </li>
  );
};
