import * as React from 'react';
import { PrintJobStatus, AvailablePrintJobStatus, statusMapping } from './constants';
import { FormattedMessage } from 'react-intl';
import { Icon, IconAppearance, Type as IconType } from '@nshift/common/components/Icon';
import { Badge, Appearance as BadgeAppearance } from '@nshift/common/components/Badge';
import { translations } from '@/locales';

interface StatusBadgeDetails {
  appearance: BadgeAppearance;
  iconAppearance: IconAppearance;
  iconType: IconType;
}

const printJobStatusBadgeDetails: Record<PrintJobStatus, StatusBadgeDetails> = {
  [PrintJobStatus.Pending]: {
    appearance: 'gray',
    iconAppearance: 'none',
    iconType: 'chevronRightAlt'
  },
  [PrintJobStatus.Error]: {
    appearance: 'red',
    iconAppearance: 'red',
    iconType: 'warning'
  },
  [PrintJobStatus.Printed]: {
    appearance: 'green',
    iconAppearance: 'green',
    iconType: 'confirmed'
  },
  [PrintJobStatus.Cancelled]: {
    appearance: 'gray',
    iconAppearance: 'none',
    iconType: 'chevronRightAlt'
  }
};

export const PrintJobsStatusBadge: React.FC<{ status: AvailablePrintJobStatus }> = ({ status }) => {
  const printJobStatus = (statusMapping[status] ?? PrintJobStatus.Pending) as PrintJobStatus;
  return (
    <>
      <Badge className="text-xs my-3 w-fit pr-3" appearance={printJobStatusBadgeDetails[printJobStatus].appearance}>
        <Icon
          type={printJobStatusBadgeDetails[printJobStatus].iconType}
          appearance={printJobStatusBadgeDetails[printJobStatus].iconAppearance}
          sizeClassName="text-xs"
          className="pr-1"
          aria-hidden="true"
        />
        {statusMapping[status] ? (
          <FormattedMessage id={translations.domains.printJobsTypeFilter[printJobStatus]} />
        ) : (
          status
        )}
      </Badge>
    </>
  );
};
