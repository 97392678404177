import React, { useState, FC } from 'react';
import { isArray } from 'lodash';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { translations } from '@/locales';
import { authorizedApi } from '@/services/network';

import { SimpleSelect } from '@nshift/common/components/Select';

interface Props {
  onChange: (apikey: string[]) => void;
}
const fetchApiKeys = () => {
  return authorizedApi.get<any>('/apikeys');
};

export const ApiKeySelector: FC<Props> = ({ onChange }) => {
  const intl = useIntl();
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([]);
  const { data: { apiKeys } = {} } = useQuery<any>('get-api-keys', fetchApiKeys, {
    // enabled: enabled,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        const keys = data.apiKeys.map((str: string) => str.split('-')[0]);
        setSelectedApiKeys(keys);
        onChange(keys);
      }
    }
  });

  return (
    <div>
      {apiKeys?.length > 0 && (
        <SimpleSelect
          multiple
          label={intl.formatMessage({ id: translations.pages.printJobs.columnsFilters.apiKeys })}
          value={selectedApiKeys}
          items={apiKeys.map((apikey: string) => ({ name: apikey.split('-')[0], value: apikey.split('-')[0] }))}
          onChange={(value: string | string[]) => {
            const list = isArray(value) ? value : [value];
            setSelectedApiKeys(list);
            onChange(list);
          }}
        />
      )}
    </div>
  );
};
