import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { COOKIES_POLICY, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '@/constants';

export const SimpleFooter: React.FC = () => (
  <div className="flex items-center w-full h-12 px-6 space-x-1 text-[13px] font-medium text-darkBlue z-10 bg-darkBlue-5 min-w-[600px]">
    <div>
      <Link to={PRIVACY_POLICY} className="hover:underline">
        <FormattedMessage id={translations.application.footer.simpleFooter.privacy} />
      </Link>
      <span>,</span>
    </div>

    <Link to={COOKIES_POLICY} className="hover:underline">
      <FormattedMessage id={translations.application.footer.simpleFooter.cookies} />
    </Link>

    <span>&</span>

    <Link to={TERMS_AND_CONDITIONS} className="hover:underline">
      <FormattedMessage id={translations.application.footer.simpleFooter.terms} />
    </Link>

    <div>
      <FormattedMessage
        id={translations.application.footer.simpleFooter.copyright}
        values={{ year: new Date().getFullYear() }}
      />
    </div>
  </div>
);
