import { Icon } from '@nshift/common/components/Icon';
import classNames from 'classnames';
import React, { FC } from 'react';
import { PrinterGroup } from '../priorities/types';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locales';

interface Props {
  activeGroupId: string | null;
  selectedGroups: PrinterGroup[];
  onGroupSelect: (groupId: string) => void;
  onGroupRemove: (groupId: string) => void;
}

export const PrinterProfileGroupsTray: FC<Props> = ({
  activeGroupId,
  selectedGroups,
  onGroupSelect,
  onGroupRemove
}) => (
  <div className="flex flex-col gap-1 min-h-32">
    <div>
      <FormattedMessage
        id={translations.pages.printerProfiles.group.profileGroups}
        values={{ count: selectedGroups.length }}
      />
    </div>
    {selectedGroups?.map(({ id, name }) => (
      <div
        key={id}
        className={classNames(
          String(id) == activeGroupId
            ? 'bg-highlightingBlue text-white'
            : 'bg-white text-darkBlue-60 hover:bg-highlightingBlue-10',
          'px-2 h-10 w-full flex justify-between items-center cursor-pointer transition-colors rounded shadow'
        )}
        onClick={(ev) => {
          onGroupSelect(String(id));
          ev.stopPropagation();
        }}
      >
        <span>{name}</span>

        <div
          className="transition-transform duration-200 cursor-pointer hover:scale-110 active:scale-95"
          onClick={(ev) => {
            onGroupRemove(id.toString()); //removeGroupFromProfile
            ev.stopPropagation();
          }}
        >
          <Icon appearance={String(id) == activeGroupId ? 'white' : 'none'} type="close" />
        </div>
      </div>
    ))}
  </div>
);
