import React from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { resetPassword } from '@/services/api';
import { STRING_LONG_MAX_LENGTH, urls } from '@/constants';

import { Button } from '@nshift/common/components/Button';
import { FormInput } from '@nshift/common/components/Input/FormInput';

import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';

const schema = yup.object().shape({
  username: yup.string().max(STRING_LONG_MAX_LENGTH).label(translations.inputs.userName.label).required()
});

export const ForgotPasswordPage: React.FC = () => {
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit((data) => resetPassword(data.username));

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.forgotPassword.title} />
      </div>

      <div className="mt-2 text-sm text-darkBlue-50">
        <FormattedMessage id={translations.pages.forgotPassword.subtitle} />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col w-full mt-6">
        <FormInput
          errors={errors}
          id="username"
          name="username"
          label={intl.formatMessage({ id: translations.inputs.userName.label })}
          register={register}
          fullWidth
        />

        <div className="flex h-10">
          {isSubmitSuccessful && (
            <div className="my-auto text-confirmingGreen">
              <FormattedMessage id={translations.pages.forgotPassword.emailSent} />
            </div>
          )}
        </div>

        <Button
          disabled={isSubmitting || isSubmitSuccessful}
          isLoading={isSubmitting}
          type="submit"
          appearance="primary"
          size="medium"
        >
          <FormattedMessage id={translations.inputs.buttons.resetPassword} />
        </Button>

        <Link to={urls.signIn} className="mt-4 text-base text-internationalBlue">
          <FormattedMessage id={translations.pages.forgotPassword.loginRegirect} />
        </Link>

        <PrivacyNotice />
      </form>
    </>
  );
};
