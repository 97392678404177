import React, { FC } from 'react';
import { useToaster } from 'react-hot-toast/headless';
import classnames from 'classnames';
import { Toast } from './Toast';
import { ToastType } from './types';

export const Toaster: FC<{ className?: string }> = ({ className }) => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const toastDetails = (toast: any) => {
    const offset = calculateOffset(toast, {
      reverseOrder: false,
      gutter: 8
    });
    const ref = (el) => {
      if (el && typeof toast.height !== 'number') {
        const height = el.getBoundingClientRect().height;
        updateHeight(toast.id, height);
      }
    };
    return { offset, ref };
  };

  return (
    <div
      className={classnames('w-full fixed flex pr-4 justify-end z-50 mt-[114px]', className)}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const { message, type, ...rest } = toast;
        const { offset, ref } = toastDetails(toast);
        return (
          <Toast
            key={toast.id}
            message={message as unknown as string}
            type={type as ToastType}
            {...{ ...rest, offset }}
            ref={ref}
          />
        );
      })}
    </div>
  );
};
