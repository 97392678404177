import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { Language } from '@/constants';
import { getTranslationMessages } from '@/locales';

const useLanguage = () => {
  const languages = [Language.English, Language.Norwegian];
  const defaultLanguage = languages[0];

  const fallback = React.useCallback(
    (language: Language) => (Object.values(Language).includes(language) ? language : defaultLanguage),
    [defaultLanguage]
  );

  const [language = defaultLanguage, update] = useLocalStorage('locale', defaultLanguage, {
    raw: false,
    serializer: fallback,
    deserializer: (value) => fallback(value as Language)
  });

  return React.useMemo(() => ({ language, languages, update }), [language, languages]);
};

const container = createContainer(useLanguage);

const IntlProviderWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { language } = container.useContainer();

  return <IntlProvider {...props} locale={language} messages={getTranslationMessages(language)} />;
};

const Provider: React.FC<React.PropsWithChildren> = (props) => (
  <container.Provider>
    <IntlProviderWrapper {...props} />
  </container.Provider>
);

export const LanguageContainer = { useContainer: container.useContainer, Provider };
