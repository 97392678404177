import React, { FC } from 'react';
import classnames from 'classnames';

import { BulkActionButton, RowActionButton } from '../types';
import { checkboxColumn } from '../constants';
import { flexRender, Table } from '@tanstack/react-table';

interface Props {
  table: Table<Record<string, unknown>>;
  bulkActionButtons?: BulkActionButton[];
  rowActionButtons?: RowActionButton[];
  thClassName?: string;
  flexRender: typeof flexRender;
}

export const TableHead: FC<Props> = ({ bulkActionButtons, rowActionButtons, table, thClassName, flexRender }) => {
  return (
    <thead className="text-white w-full bg-internationalBlue text-xs">
      {table?.getHeaderGroups()?.map((headerGroup) => (
        <tr className="w-full  h-12" key={headerGroup.id}>
          {bulkActionButtons?.length > 0 ? (
            <th className="text-start px-5" key="check">
              {checkboxColumn.header({ table })}
            </th>
          ) : (
            <th />
          )}
          {headerGroup?.headers?.map((header) => {
            return (
              <th className={classnames('text-start', thClassName)} key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : <>{flexRender(header.column.columnDef.header, header.getContext())}</>}
              </th>
            );
          })}
          {rowActionButtons?.length > 0 && <th />}
        </tr>
      ))}
    </thead>
  );
};
