import { AxiosRequestHeaders } from 'axios';
import { api, authorizedApi } from '@/services/network';

export interface LoginOptions {
  username: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

const addAuthToken = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } as AxiosRequestHeaders });

export const login = (data: LoginOptions) => api.post<LoginResponse>(`/auth`, data);

export const refreshAuth = (refreshToken: string) => api.post<LoginResponse>(`/auth/refresh`, { refreshToken });

export const logout = () => authorizedApi.post<any>('/auth/logout');

export const resetPassword = (username: string) => api.post('/account/reset-password', { username });

export const checkToken = (token: string) => api.get('/password/token', {}, addAuthToken(token));

export const activatePassword = (password: string, token: string) =>
  api.post('/password/activate', { password }, addAuthToken(token));

export const changePassword = (password: string, token: string) =>
  api.post('/password/reset', { password }, addAuthToken(token));

// TODO add proper user profile endpoint integration once it's implemented
export const getProfile = () => Promise.resolve({ name: 'John Smith' });
