import { Icon } from '../Icon';
import { TableRowActionsMenu } from './BaseTable/TableRowActionsMenu';
import { TableCheckbox } from './TableCheckbox';
import React from 'react';

export const tablePageSizeOptions = [
  { name: '5', id: '5' },
  { name: '10', id: '10' },
  { name: '15', id: '15' },
  { name: '30', id: '30' },
  { name: '50', id: '50' }
];

export const rowActionsColumn = (rowActions, openDetailsDrawerFn) => ({
  id: 'actions',
  header: () => (
    <div className="flex justify-end">
      <Icon type="filter" appearance="white" sizeClassName="text-xs" className="pl-3" aria-hidden="true" />
    </div>
  ),
  cell: (row: Record<string, unknown>) => (
    <TableRowActionsMenu row={row} actionButtons={rowActions} openDetailsDrawerFn={openDetailsDrawerFn} />
  ),
  sortable: false
});

export const checkboxColumn = {
  id: 'check',
  header: ({ table }) => (
    <TableCheckbox
      {...{
        checked: table?.getIsAllRowsSelected?.(),
        indeterminate: table?.getIsSomeRowsSelected?.(),
        onChange: table?.getToggleAllRowsSelectedHandler?.()
      }}
    />
  ),
  cell: ({ row }) => (
    <div
      className="px-5 min-h-12 flex items-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TableCheckbox
        {...{
          checked: row?.getIsSelected?.(),
          disabled: !row?.getCanSelect?.(),
          indeterminate: row?.getIsSomeSelected?.(),
          onChange: row?.getToggleSelectedHandler?.()
        }}
      />
    </div>
  )
};
