import React, { useState } from 'react';
import { BaseTable } from './BaseTable';
import TableFooter from './TableFooter';
import { isNil } from 'lodash';
import { TableRowDetailsDrawer } from './TableRowDetailsDrawer';
import { TableBulkActionsBar } from './TableBulkActionsBar';

export interface Props {
  id: string;
  columns: any;
  data?: Record<string, unknown>[];
  bulkActionButtons?: any[];
  rowActionButtons?: any[];
  drawerActionButtons?: any[];
  defaultPageSize?: number;
  selectedPage?: number;
  rowCount?: number;
  renderRowDetailsSection?: (row: any) => React.ReactNode;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (page: number) => void;
  defaultSelectedPage?: number;
  rowIdProperty?: string;
  pageHasHeader?: boolean;
  noResultsMessage?: string;
  noResultsTitle?: string;
  loading?: boolean;
}

export const Table: React.FC<Props> = ({
  id,
  defaultPageSize = 5,
  selectedPage = 1,
  rowCount,
  rowIdProperty = 'id',
  bulkActionButtons,
  drawerActionButtons,
  defaultSelectedPage,
  renderRowDetailsSection,
  onPageChange,
  onPageSizeChange,
  data = [],
  pageHasHeader = true,
  noResultsTitle,
  noResultsMessage,
  loading,
  ...rest
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [page, setPage] = useState(selectedPage);
  const [clickedRow, setClickedRow] = useState<Record<string, unknown>>();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const tableRef = React.useRef<any>();

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    onPageSizeChange?.(size);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    onPageChange?.(page);
  };

  const handleSelectedRowIdsChange = (tableRowIndexes: string[]) => {
    const entityIds = data
      .filter((row: Record<string, unknown>) => {
        const index = data.indexOf(row);
        return tableRowIndexes.includes(index.toString());
      })
      .map((row: Record<string, unknown>) => {
        return row[rowIdProperty];
      });
    setSelectedRowIds(entityIds);
  };

  return (
    <div id={id}>
      <BaseTable
        {...{ ...rest, data, bulkActionButtons, rowCount, loading, noResultsMessage, noResultsTitle }}
        ref={tableRef}
        onSelectionChange={handleSelectedRowIdsChange}
        onRowClick={setClickedRow}
        thClassName="uppercase"
      />
      {!isNil(rowCount) && !loading && data?.length > 0 && (
        <TableFooter
          selectedPage={page}
          rowCount={rowCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      )}
      {!isNil(clickedRow) && !isNil(renderRowDetailsSection) && (
        <TableRowDetailsDrawer
          row={clickedRow}
          actionButtons={drawerActionButtons}
          onClose={() => {
            setClickedRow(undefined);
          }}
          className={!pageHasHeader ? '!top-0' : ''}
        >
          {renderRowDetailsSection(clickedRow)}
        </TableRowDetailsDrawer>
      )}
      {!isNil(bulkActionButtons) && bulkActionButtons.length > 0 && selectedRowIds.length > 0 && (
        <TableBulkActionsBar
          bulkActionButtons={bulkActionButtons}
          selectedRowIds={selectedRowIds}
          onClose={() => {
            tableRef.current.clearRowSelection();
          }}
        />
      )}
    </div>
  );
};
