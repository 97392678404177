import React, { Dispatch, SetStateAction, forwardRef } from 'react';
import { useGridList } from 'react-aria';
import { useListState } from 'react-stately';

import { GridListItem } from './GridListItem';

export interface GridListProps {
  ref: React.RefObject<HTMLUListElement>;
  items: any[];
  selectedKeys?: any[] | number[];
  onSelectionChange?: Dispatch<SetStateAction<Set<any>>>;
  selectionMode: 'single' | 'multiple';
  type?: 'checkboxList' | 'list';
  children: React.ReactNode;
}

export const GridList = forwardRef<HTMLUListElement, GridListProps>(({ type = 'checkboxList', ...props }, ref) => {
  const state = useListState(props);
  const { gridProps } = useGridList(props, state, ref);
  const itemType = type === 'checkboxList' ? 'checkboxItem' : 'item';
  return (
    <ul {...gridProps} ref={ref} className="w-full overflow-y-auto bg-white border-none rounded-sm max-h-96">
      {[...(state.collection || [])].map((item) => (
        <GridListItem type={itemType} key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
});
