import React from 'react';

import { DismissButton, Overlay, usePopover } from 'react-aria';
import type { AriaPopoverProps, Placement } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';
import classnames from 'classnames';

import styles from './Popover.module.scss';

interface PopoverProps extends AriaPopoverProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
  placement?: Placement;
  popoverRef: React.RefObject<HTMLDivElement>;
  className?: string;
  showArrow?: boolean;
}

export const Popover = ({ className, children, state, showArrow = false, ...props }: PopoverProps) => {
  const popoverRef = React.useRef(null);
  const {
    popoverProps,
    underlayProps,
    arrowProps,
    placement: arrowPlacement
  } = usePopover({ ...props, popoverRef }, state);

  return (
    <Overlay>
      <div {...underlayProps} onClick={state.close} className="fixed inset-0"></div>
      <div className={classnames('rounded-md shadow-lg', className)} {...popoverProps} ref={popoverRef}>
        {showArrow && (
          <svg
            {...arrowProps}
            className={classnames('fill-darkBlue stroke-darkBlue h-2 w-2 absolute', styles.arrow)}
            data-placement={arrowPlacement}
            viewBox="0 0 12 12"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        )}
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};
