import { ToastDetails, ToastType } from './types';

export const mapToastDetails: Record<ToastType, ToastDetails> = {
  [ToastType.Error]: {
    background: 'bg-warningRed-20',
    topBorder: 'bg-warningRed',
    text: 'text-darkRed-STRONG',
    iconAppearance: 'red',
    iconType: 'warning'
  },
  [ToastType.Success]: {
    background: 'bg-confirmingGreen-30',
    text: 'text-confirmingGreen-STRONG',
    topBorder: 'bg-confirmingGreen',
    iconAppearance: 'green',
    iconType: 'confirmed'
  }
};
