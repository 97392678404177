import {
  Renderable,
  ValueOrFunction,
  toast as ogToast,
  Toast as OgToast,
  ToastOptions as OriginalToastOptions
} from 'react-hot-toast/headless';
import { ToastHandler, ToastType } from './types';

const createHandler = (type: ToastType) =>
  ((message, options) =>
    // This is needed unfortunately due to types being different despite working
    ogToast(
      message as ValueOrFunction<Renderable, OgToast>,
      {
        ...options,
        type
      } as OriginalToastOptions
    )) as ToastHandler;

export const toast = (...args: Parameters<ToastHandler>): string => ogToast(...(args as Parameters<typeof ogToast>));
toast.error = createHandler(ToastType.Error);
toast.success = createHandler(ToastType.Success);
toast.dismiss = ogToast.dismiss;
toast.remove = ogToast.remove;
