import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { SimpleCombobox, SimpleComboboxProps } from './SimpleCombobox';

interface FormComboboxProps extends SimpleComboboxProps {
  control: Control<FieldValues>;
}

export const FormCombobox: React.FC<FormComboboxProps> = ({ name, control, required, ...rest }) => {
  const { field } = useController({
    name,
    control
  });

  return (
    <SimpleCombobox
      onSelectionChange={field.onChange}
      onBlur={field.onBlur}
      defaultSelectedKeys={field.value instanceof Array ? field.value : [field.value]}
      name={field.name}
      required={required}
      {...rest}
    />
  );
};
