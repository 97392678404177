import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BulkActionButton, RowActionButton } from '../types';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';

interface Props {
  columns: ColumnDef<Record<string, unknown>, any>[];
  data: Record<string, unknown>[];
  bulkActionButtons?: BulkActionButton[];
  rowActionButtons?: RowActionButton[];
  onSelectionChange?: (ids: string[]) => void;
  onRowClick?: (row: Record<string, unknown>) => void;
  rowCount?: number;
  thClassName?: string;
  trClassName?: string;
  noResultsTitle?: string;
  noResultsMessage?: string;
  loading?: boolean;
}

export const BaseTable = forwardRef<any, Props>(
  (
    {
      columns,
      data,
      bulkActionButtons = [],
      rowActionButtons = [],
      onSelectionChange,
      onRowClick,
      rowCount,
      thClassName,
      trClassName,
      loading = false,
      noResultsTitle,
      noResultsMessage
    },
    ref
  ) => {
    const [rowSelection, setRowSelection] = React.useState({});

    useImperativeHandle(
      ref,
      () => ({
        clearRowSelection: () => setRowSelection({})
      }),
      []
    );

    useEffect(() => {
      onSelectionChange?.(Object.keys(rowSelection));
    }, [rowSelection]);

    const table = useReactTable({
      data,
      columns,
      state: {
        rowSelection
      },
      enableRowSelection: true,
      manualPagination: true,
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      rowCount,
      debugTable: true
    });

    return (
      <div className="overflow-auto">
        <table className="w-full">
          <TableHead
            table={table}
            bulkActionButtons={bulkActionButtons}
            rowActionButtons={rowActionButtons}
            thClassName={thClassName}
            flexRender={flexRender}
          />
          <TableBody
            table={table}
            columns={columns}
            loading={loading}
            bulkActionButtons={bulkActionButtons}
            rowActionButtons={rowActionButtons}
            trClassName={trClassName}
            flexRender={flexRender}
            noResultsTitle={noResultsTitle}
            noResultsMessage={noResultsMessage}
            onRowClick={onRowClick}
          />
        </table>
      </div>
    );
  }
);
