import React, { FC } from 'react';
import { Me } from 'app/src/domains/Me';
import classNames from 'classnames';
import profilePic from '@/assets/profilePerson.svg';

interface Props {
  me: Me;
  rounded?: boolean;
  outline?: boolean;
  className?: string;
}

export const UserImage: FC<Props> = ({ className, outline, rounded, me }) => {
  const { name } = me;

  return (
    <div
      className={classNames(
        `w-8 h-8 flex items-center justify-center overflow-hidden text-white shadow bg-gradient-to-br from-internationalBlue to-nordicGreen`,
        rounded ? 'rounded-full' : 'rounded-3xl',
        outline ? 'border-[3px] border-solid border-white' : 'border-none',
        className
      )}
    >
      <img src={profilePic} alt={`${name} Profile avatar`} className="object-cover object-center w-4 h-4" />
    </div>
  );
};
