import { AxiosRequestHeaders } from 'axios';

import ApiClient from '@nshift/common/services/network/api';
import { HttpRequestConfig } from '@nshift/common/services/network/http';
import { getBaseURL } from '../getBaseURL';

export const api = new ApiClient<HttpRequestConfig>({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json'
    // [Header.Version]: RELEASE
    //This is a known issue in this version of axios, it's just a typescript error, but the code works
  } as unknown as AxiosRequestHeaders
});
