import React, { useState, FC } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { getPrintJobs } from '@/services/api/printJobs';
import { useTableManager } from '@/hooks/useTableManager';

import { Table } from '@nshift/common/components/Table';

import { ConfirmDialog } from '@/components/Dialogs';

import { ApiKeySelector } from './ApiKeySelector';
import { PrintJobDetailsView } from './PrintJobDetailsView';
import { DuplicateActionDialog } from './DuplicateActionDialog';
import { usePrintJobsTableConfig } from './PrintJobsTableConfig';
import { Filters as PrintJobsFilters, PrintJobsFilterSection } from './PrintJobsFilters';
import { PrintJobDialogs } from './constants';
import { PrintJobsResponse } from './types';

const initialFilterState: PrintJobsFilters = {
  dateFrom: moment().add(3, 'days').subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss'),
  dateTo: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
  reference: '',
  statuses: [],
  platformObjectId: '',
  printId: ''
};

const PrintJobsPage: FC = () => {
  const intl = useIntl();
  const [apiKeys, setApiKeys] = useState<string[]>([]);
  const { pageSize, page, filters, setPageSize, setPage, setFilters } =
    useTableManager<PrintJobsFilters>(initialFilterState);

  const [openedDialog, setOpenedDialog] = useState<any>();

  const { data: { printjobs = [], totalPages } = {}, isLoading } = useQuery<PrintJobsResponse>(
    ['printjobs', page, pageSize, apiKeys, filters],
    getPrintJobs(page, pageSize, apiKeys, filters),
    { enabled: apiKeys.length > 0 }
  );

  const { bulkActionButtons, rowActionButtons, drawerActionButtons, colsConfig } = usePrintJobsTableConfig({
    onDialogOpen: setOpenedDialog
  });

  const onCloseDialog = () => {
    setOpenedDialog(undefined);
  };

  return (
    <div className="w-full py-20">
      <div className="flex items-center justify-between px-6 mb-6">
        <h1 className="text-3xl font-semibold">
          <FormattedMessage id={translations.pages.printJobs.title} />
        </h1>

        <ApiKeySelector onChange={setApiKeys} />
      </div>

      <div className="flex flex-col py-6 mx-6 bg-white rounded-lg shadow">
        <div className="px-6">
          <PrintJobsFilterSection
            initialFilterState={initialFilterState}
            defaultFilterState={filters}
            onApplyFilters={setFilters}
          />
        </div>

        <Table
          id="PrintJobsTable"
          columns={colsConfig}
          data={printjobs}
          {...{ bulkActionButtons, rowActionButtons, drawerActionButtons }}
          selectedPage={page + 1}
          defaultPageSize={pageSize}
          rowCount={(totalPages ?? 0) * pageSize}
          onPageSizeChange={setPageSize}
          onPageChange={(page) => {
            setPage(page - 1);
          }}
          renderRowDetailsSection={(row: any) => <PrintJobDetailsView row={row} />}
          noResultsTitle={intl.formatMessage(
            { id: translations.miscellaneous.table.empty.title },
            { items: intl.formatMessage({ id: translations.pages.printJobs.items }) }
          )}
          noResultsMessage={intl.formatMessage({ id: translations.miscellaneous.table.empty.message })}
          loading={isLoading}
        />
      </div>

      <DuplicateActionDialog
        onClose={onCloseDialog}
        isOpen={openedDialog?.name === PrintJobDialogs.Duplicate}
        rowIds={openedDialog?.rowIds ?? []}
      />

      <ConfirmDialog
        isOpen={openedDialog?.name === PrintJobDialogs.Delete}
        title={intl.formatMessage({ id: translations.pages.printJobs.dialogs.delete.title })}
        onClose={onCloseDialog}
        onConfirm={() => {
          console.log('Delete printJobs with id: ', openedDialog?.rowIds);
        }}
      >
        <FormattedMessage
          id={translations.pages.printJobs.dialogs.delete.message}
          values={{ count: (openedDialog?.rowIds ?? []).length }}
        />
      </ConfirmDialog>

      <ConfirmDialog
        isOpen={openedDialog?.name === PrintJobDialogs.Resend}
        title={intl.formatMessage({ id: translations.pages.printJobs.dialogs.resend.title })}
        onClose={onCloseDialog}
        onConfirm={() => {
          console.log('Resend printJobs with id: ', openedDialog?.rowIds);
        }}
      >
        <FormattedMessage
          id={translations.pages.printJobs.dialogs.resend.message}
          values={{ count: (openedDialog?.rowIds ?? []).length }}
        />
      </ConfirmDialog>
    </div>
  );
};

export default PrintJobsPage;
