export const apis = {
  QA: 'https://ndi.qa.nshift.dev/dpc-ui-gateway',
  PP: 'https://ndi.pp.nshift.dev/dpc-ui-gateway',
  PROD: 'https://print.nshiftportal.com/dpc-ui-gateway'
};

export const localURLs = {
  QA: 'https://ndi.qa.nshift.dev',
  PP: 'https://ndi.pp.nshift.dev',
  PROD: 'https://print.nshiftportal.com'
};
