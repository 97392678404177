import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { TextField } from './TextField';
import { get } from 'lodash';

interface FormTextFieldProps extends React.ComponentProps<typeof TextField> {
  control: Control<FieldValues>;
  errors: any;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({ errors, name, control, required, ...rest }) => {
  const { field } = useController({
    name,
    control
  });

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <TextField
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      required={required}
      {...{ ...rest, hasError, errorMessages }}
    />
  );
};
