import React, { FC } from 'react';
import printer from '@nshift/common/assets/printer.svg';
import classNames from 'classnames';

interface Props {
  title?: string;
  message?: string;
  className?: string;
}
export const TableEmpty: FC<Props> = ({
  title = 'No results found',
  message = 'The search did not match any results. Try altering the search or change the filters.',
  className
}) => {
  return (
    <div className={classNames('flex items-center justify-center gap-6 py-12', className)}>
      <img src={printer} className="max-w-52 md:max-w-64" />
      <div className="flex flex-col gap-2">
        <h2 className="text-xl">{title}</h2>
        <div className="font-normal text-base text-darkBlue-70 max-w-80">{message}</div>
      </div>
    </div>
  );
};
