import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { useFocusRing, useOption, useDraggableItem, mergeProps } from 'react-aria';

import { Icon } from '@nshift/common/components/Icon';

import { DropIndicator } from './DropIndicator';

export const ReorderableOption = ({ item, state, dragState, dropState, disabled, showDeleteButtons }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const ref = React.useRef(null);
  const { optionProps } = useOption({ key: item.key }, state, ref);
  // Register the item as a drag source.
  const { dragProps } = useDraggableItem({ key: item.key }, dragState);

  /**
   * Handle drag start to create a custom drag image for replacing the default one.
   * Inspired by: https://phuoc.ng/collection/react-drag-drop/customize-the-appearance-of-a-ghost-element/
   */
  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    const dragEle = e.target as HTMLElement;
    const elementWidth = dragEle.offsetWidth;

    const ghostEle = dragEle.cloneNode(true) as HTMLElement;
    // Hide the inserted element as it is only used for styling the ghost element.
    ghostEle.classList.add('translate-x-[200%]');
    ghostEle.classList.add('!bg-highlightingBlue', 'text-white');
    ghostEle.style.width = `${elementWidth}px`;
    document.body.appendChild(ghostEle);

    const nodeRect = dragEle.getBoundingClientRect();
    e.dataTransfer.setDragImage(ghostEle, e.clientX - nodeRect.left, e.clientY - nodeRect.top);

    const handleDragEnd = () => {
      ghostEle.remove();
      dragEle.removeEventListener('dragend', handleDragEnd);
    };
    dragEle.addEventListener('dragend', handleDragEnd);
  };

  return (
    <>
      <DropIndicator target={{ type: 'item', key: item.key, dropPosition: 'before' }} dropState={dropState} />
      <li
        {...mergeProps(optionProps, dragProps, focusProps)}
        ref={ref}
        className={classNames(
          'px-2 h-10 w-full flex justify-between items-center transition-colors rounded shadow',
          isFocusVisible ? 'focus-visible' : '',
          disabled
            ? 'text-darkBlue-50 cursor-not-allowed bg-internationalBlue-3'
            : 'cursor-grab bg-white hover:bg-highlightingBlue-10'
        )}
        onDragStart={(event) => {
          handleDragStart(event);
          dragProps.onDragStart(event);
        }}
        aria-disabled={disabled}
      >
        <div className="flex items-center gap-2">
          <Icon type="dragSort" size="small" />

          {item.rendered}
        </div>

        {showDeleteButtons && (
          <Icon
            type="minus"
            size="medium"
            light={disabled}
            className={disabled ? 'cursor-not-allowed' : 'cursor-pointer active:scale-95 transition-all'}
          />
        )}
      </li>

      {isNil(state.collection.getKeyAfter(item.key)) && (
        <DropIndicator target={{ type: 'item', key: item.key, dropPosition: 'after' }} dropState={dropState} />
      )}
    </>
  );
};
