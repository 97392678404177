import React, { FC, forwardRef } from 'react';
import classnames from 'classnames';
import { Icon } from '@nshift/common/components/Icon';

import { ToastType } from './types';
import { mapToastDetails } from './constants';
import { toast } from './createToast';

export interface Props {
  type?: ToastType;
  className?: string;
  id: string;
  visible: boolean;
  ariaProps: Record<string, any>;
  message: string;
  offset: number;
  ref: any;
}

export const Toast: FC<Props> = forwardRef(
  ({ type = ToastType.Success, id, visible, ariaProps, message, offset }, ref: any) => {
    const { iconType, iconAppearance, topBorder, text, background } = mapToastDetails[type];
    return (
      <div
        key={id}
        ref={ref}
        style={{
          transition: 'all 0.5s ease-out',
          opacity: visible ? 1 : 0,
          transform: `translateY(${offset}px)`
        }}
        {...ariaProps}
        className={classnames('w-fit absolute rounded-md overflow-hidden min-w-[350px]', background)}
      >
        <div className={classnames(topBorder, 'h-1.5')} />
        <div className={classnames('flex whitespace-nowrap items-center text-xs mb-2 mt-1 ml-1.5 font-semibold', text)}>
          <Icon
            type={iconType}
            appearance={iconAppearance}
            sizeClassName="text-base"
            className="pr-1"
            aria-hidden="true"
          />
          {message}
          <div
            onClick={(ev) => {
              ev.stopPropagation();
              toast.dismiss(id);
            }}
            className={classnames('pr-1 ml-auto mr-2', text)}
          >
            <Icon type="close" sizeClassName="text-base" className={text} aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
);
