import React, { FC, SetStateAction, useEffect, useRef, useMemo } from 'react';

import { useOverlayTrigger } from 'react-aria';
import { Item, useComboBoxState, useOverlayTriggerState } from 'react-stately';

import { NewTextInput } from '@nshift/common/components/Input/NewTextInput';
import { Icon } from '../Icon';
import { GridList } from '../Select';
import { Popover } from '../Modal/Popover';

import { findSelectedName } from './constants';
import classnames from 'classnames';

interface Item {
  id: string;
  name: string;
}

export interface SimpleComboboxProps {
  label?: string;
  defaultSelectedKeys?: any[];
  items?: Item[];
  onSelectionChange?: (selection: string[]) => void;
  onBlur?: (event: unknown) => void;
  selectionMode?: 'single' | 'multiple';
  errors?: any;
  name: string;
  disabled?: boolean;
  noBorder?: boolean;
  className?: string;
  required?: boolean;
}

export const SimpleCombobox: FC<SimpleComboboxProps> = ({
  label,
  defaultSelectedKeys,
  items,
  selectionMode = 'single',
  onSelectionChange,
  onBlur,
  errors,
  name,
  disabled,
  className,
  noBorder,
  required,
  ...props
}) => {
  const inputRef = useRef(null);
  const parentRef = useRef(null);
  const gridListRef = useRef(null);
  const popoverRef = useRef(null);

  const overlayState = useOverlayTriggerState(props);

  const [selectedKeys, setSelectedKeys] = React.useState(new Set(defaultSelectedKeys));
  useEffect(() => {
    setSelectedKeys(new Set(defaultSelectedKeys));
  }, [defaultSelectedKeys]);

  const displayValue = useMemo(() => {
    const selected = Array.from(selectedKeys);
    if (selected.length > 0 && items.length > 0) {
      return selected.map((key) => findSelectedName(key, items)).join(', ');
    }
    return '';
  }, [selectedKeys, items]);

  const [inputFieldValue, setInputFieldValue] = React.useState(displayValue);
  const filteredItems = React.useMemo(() => {
    return inputFieldValue.length > 0
      ? items?.filter((item) => item.name?.toLowerCase().includes(inputFieldValue.toLowerCase()))
      : items;
  }, [inputFieldValue, items]);

  const state = useComboBoxState({ ...props, defaultItems: selectedKeys });

  useEffect(() => {
    setInputFieldValue(displayValue);
  }, [displayValue]);

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, popoverRef);

  // Make sure that the dropdown has the same width as the input field.
  useEffect(() => {
    if (overlayState.isOpen && gridListRef) {
      const inputWidth = inputRef.current.getBoundingClientRect().width;
      gridListRef.current.style.minWidth = `${inputWidth}px`;
    }
  }, [parentRef, overlayState.isOpen]);

  const handleInputChange = ({ target: { value } }) => {
    setInputFieldValue(value);
    overlayState.open();
  };

  const onSelection = (key: SetStateAction<any>) => {
    if (selectionMode === 'single') {
      overlayState.close();
    }
    setSelectedKeys?.(key);
    onSelectionChange?.(Array.from(key));
  };

  const clearInputText = () => {
    setInputFieldValue('');
    setSelectedKeys?.(new Set([]));
    onSelectionChange?.([]);
  };

  return (
    <div className={classnames('inline-flex flex-col', className)}>
      <div className="flex flex-col w-full" ref={parentRef}>
        <div
          ref={inputRef}
          onClick={overlayState.open}
          className={classnames('relative', disabled && 'pointer-events-none')}
        >
          <NewTextInput
            {...{ ...triggerProps, noBorder }}
            id="combobox-input"
            name={name}
            label={label}
            onFocus={overlayState.open}
            onChange={handleInputChange}
            value={inputFieldValue}
            inputClassName="pr-[60px] w-full"
            className="w-full"
            errors={errors}
            disabled={disabled}
            onBlur={onBlur}
            required={required}
          />
          <div className={classnames('absolute flex justify-end ml-auto top-3 right-2')}>
            {inputFieldValue.length > 0 && (
              <div
                className={classnames('border-r border-r-darkBlue-30 pr-2 mr-2 flex justify-center')}
                onClick={clearInputText}
              >
                <Icon type="close" sizeClassName="text-md" aria-hidden="true" />
              </div>
            )}
            <Icon type="chevronDownAlt" sizeClassName="text-md" aria-hidden="true" />
          </div>
        </div>
        {overlayState.isOpen && (
          <Popover {...overlayProps} triggerRef={inputRef} popoverRef={popoverRef} state={overlayState}>
            <GridList
              ref={gridListRef}
              items={filteredItems}
              selectionMode={selectionMode}
              selectedKeys={Array.from(selectedKeys)}
              onSelectionChange={onSelection}
              type={selectionMode === 'single' ? 'list' : 'checkboxList'}
            >
              {filteredItems?.map((item) => <Item key={`${item.id}`}>{item.name}</Item>)}
            </GridList>
          </Popover>
        )}
      </div>
    </div>
  );
};
