import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { passwordSchema } from '@/schemas';
import { changePassword } from '@/services/api';

import { Button } from '@nshift/common/components/Button';
import { FormInput } from '@nshift/common/components/Input/FormInput';

import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';

const schema = yup.object().shape({
  password: passwordSchema,
  passwordConfirmation: passwordSchema.oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch)
});

export const ResetPasswordPage: React.FC = () => {
  const intl = useIntl();

  const { search } = useLocation();

  const token = new URLSearchParams(search).get('token') ?? '';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting }
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const [passwordSentSuccessfully, setPasswordSentSuccessfully] = React.useState(false);

  const onSubmit = handleSubmit((data) =>
    changePassword(data.password, token)
      .then(() => {
        setPasswordSentSuccessfully(true);
      })
      .catch(() => setPasswordSentSuccessfully(false))
  );

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.resetPassword.title} />
      </div>

      <div className="mt-2 text-sm text-darkBlue-50">
        <FormattedMessage id={translations.pages.resetPassword.subtitle} />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col w-full mt-6">
        <FormInput
          errors={errors}
          type="password"
          id="password"
          name="password"
          label={intl.formatMessage({ id: translations.inputs.newPassword.label })}
          register={register}
          fullWidth
        />

        <FormInput
          errors={errors}
          type="password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label={intl.formatMessage({ id: translations.inputs.newPasswordConfirmation.label })}
          register={register}
          className="mt-4"
          fullWidth
        />

        <div className="flex items-center h-10">
          {isSubmitSuccessful && passwordSentSuccessfully && (
            <div className="text-base text-confirmingGreen">
              <FormattedMessage
                id={translations.pages.resetPassword.passwordChanged}
                values={{
                  a: ((text: string) => (
                    <Link to={urls.signIn} className="underline text-internationalBlue">
                      {text}
                    </Link>
                  )) as unknown as React.ReactNode
                }}
              />
            </div>
          )}
          {isSubmitSuccessful && !passwordSentSuccessfully && (
            <div className="text-base text-warningRed">
              <FormattedMessage id={translations.pages.resetPassword.passwordChangeError} />
            </div>
          )}
        </div>

        <Button
          type="submit"
          disabled={isSubmitting || isSubmitSuccessful}
          isLoading={isSubmitting}
          appearance="primary"
          size="medium"
        >
          <FormattedMessage id={translations.inputs.buttons.resetPassword} />
        </Button>

        <PrivacyNotice />
      </form>
    </>
  );
};
