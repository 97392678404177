import React, { PropsWithChildren } from 'react';
import { useTextField } from 'react-aria';
import type { AriaTextFieldProps } from 'react-aria';

import { Size, inputStyling, transformBetweenPlaceholderAndLabel, sizeToStyleMap } from './constants';
import classNames from 'classnames';
import { RequiredTag } from '../RequiredTag';

export interface TextFieldProps extends PropsWithChildren<AriaTextFieldProps> {
  size?: Size;
  description?: string;
  isDisabled?: boolean;
  renderIcon?: () => React.ReactNode;
  onClick?: any;
  onChange?: any;
  value?: any;
  className?: string;
  inputClassName?: string;
  errorMessage?: string;
  hasError?: boolean;
  required?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
  size = Size.Medium,
  isDisabled = false,
  hasError = false,
  errorMessage,
  label,
  onChange,
  onClick,
  value,
  description,
  renderIcon,
  className,
  inputClassName,
  required,
  children,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const [isFocused, setIsFocused] = React.useState(false);

  const { labelProps, inputProps, descriptionProps, errorMessageProps, isInvalid, validationErrors } = useTextField(
    { isDisabled, isInvalid: hasError, errorMessage, label, description, ...props },
    inputRef
  );

  const handleFocus = (event) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(event);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(event);
  };

  return (
    <div className={classNames('relative w-fit group', className)}>
      <label
        {...labelProps}
        className={classNames(
          'absolute z-[1] pointer-events-none h-fit',
          'max-w-[calc(100%-22px)]',
          'text-ellipsis overflow-hidden whitespace-nowrap select-none',
          'transition-all duration-200',
          transformBetweenPlaceholderAndLabel(!!props.placeholder || !!value, isDisabled, size, isFocused)
        )}
      >
        {label} {required && <RequiredTag />}
      </label>

      <input
        {...{ onChange, value, inputProps }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={onClick}
        ref={inputRef}
        className={classNames(
          sizeToStyleMap[size].inputStyle,
          inputStyling(isDisabled, isInvalid),
          isDisabled ? 'placeholder:text-darkBlue-30' : 'placeholder:text-darkBlue-40',
          inputClassName
        )}
      />
      {children}

      {renderIcon && <div className="absolute top-0 flex items-center h-full right-3">{renderIcon()}</div>}

      {description && (
        <legend
          {...descriptionProps}
          className={classNames(
            'mt-2',
            isInvalid || validationErrors.length > 0 ? 'text-warningRed' : 'text-darkBlue-60',
            isDisabled && '!text-darkBlue-20',
            sizeToStyleMap[size].descriptionStyle
          )}
        >
          {description}
        </legend>
      )}

      {isInvalid && (
        <legend
          {...errorMessageProps}
          className={classNames('text-warningRed mt-2', sizeToStyleMap[size].descriptionStyle)}
        >
          {validationErrors.join(' ')}
        </legend>
      )}
    </div>
  );
};
